import { margin, text } from '@prototyp/gatsby-plugin-gumball/utils';
import { Grid } from 'components/grid';
import Arrow from 'assets/icons/arrowRight.svg';
import React from 'react';
import { header } from './DetailsHeader.styles';
import { motion } from 'framer-motion';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { usePrismicImages } from 'hooks';
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-react-intl';
import InView from 'react-intersection-observer';

interface Props {
  document: PrismicDocument;
  path: string;
}

interface QueryType {
  edges: [
    {
      node: {
        data: {
          image: IGatsbyImageData;
        };
        uid: string;
      };
    },
  ];
}

export const DetailsHeader: React.FC<Props> = ({ document, path }) => {
  const { formatMessage } = useIntl();
  const { blogPostsQuery, destinationsQuery } = usePrismicImages();

  function getMatchingNode(query: QueryType) {
    return query.edges.find((edge) => edge.node.uid === document.node._meta.uid)
      ?.node.data.image;
  }

  let image;
  if (path === 'blog') image = getMatchingNode(blogPostsQuery);
  if (path === 'destinations') image = getMatchingNode(destinationsQuery);

  const imageData = image && getImage(image);

  return (
    <section css={header.root}>
      <Grid type="page">
        <motion.p
          initial={{ opacity: 0, y: '40px' }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ ease: 'easeInOut', duration: 0.4 }}
          css={header.category}
        >
          {document?.node.category_name && (
            <FormattedMessage
              id={`categories.${document?.node.category_name.toLowerCase()}`}
            />
          )}
        </motion.p>
        <motion.h1
          initial={{ opacity: 0, y: '40px' }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ ease: 'easeInOut', duration: 0.4 }}
          css={header.title}
        >
          {document?.node.title[0].text}
        </motion.h1>
        {imageData && image ? (
          <motion.div
            initial={{ opacity: 0, y: '40px' }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.1 }}
            css={header.imgWrapper}
          >
            <GatsbyImage
              css={header.img}
              loading="lazy"
              image={imageData}
              alt={document?.node.image.alt || ''}
            />
          </motion.div>
        ) : (
          <div css={header.imgWrapper}>
            <img
              css={header.img}
              loading="lazy"
              src={document?.node.image.url}
              alt={document?.node.image.alt}
            />
          </div>
        )}
        <InView threshold={0.4} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0, y: '40px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '40px' }}
              transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.1 }}
              css={header.linkWrapper}
            >
              <Link css={[header.link, text.color.primary]} to="/">
                <FormattedMessage id="breadcrumb.home" />
              </Link>
              <Arrow css={[margin.right.med, margin.left.med]} />
              <Link css={[header.link, text.color.primary]} to={`/${path}/`}>
                {path === 'destinations'
                  ? formatMessage({ id: 'breadcrumb.destinations' })
                  : 'Blog'}
              </Link>
              <Arrow css={[margin.right.med, margin.left.med]} />
              <p css={[header.titleSecondary, header.current]}>
                {document?.node.title[0].text}
              </p>
            </motion.div>
          )}
        </InView>
      </Grid>
    </section>
  );
};
