import { css } from '@emotion/react';
import { colors } from 'style';

const labelName = css`
  font-family: var(--font-family-secondary);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-small);

  margin-bottom: var(--unit);
`;

const labelNameErr = css`
  color: ${colors.error};
`;

const labelNameSucc = css`
  color: ${colors.text};
`;

const textarea = css`
  width: 100%;

  font-family: var(--font-family-secondary);
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-small);

  &::placeholder {
    color: ${colors.border};
  }

  color: ${colors.text};
  border-radius: 6px;
  outline: none;

  padding: calc(var(--unit) * 4 - 2px) calc(var(--unit) * 4);
  margin-bottom: calc(var(--unit) * 9);

  transition: all 0.2s ease-in-out;
`;

const textareaErr = css`
  border: 1px solid ${colors.error};

  &:focus {
    border: 2px solid ${colors.error};
  }
`;

const textareaSucc = css`
  border: 1px solid ${colors.border};

  &:focus {
    border: 2px solid ${colors.primary};
  }
`;

export const textareaStyles = {
  labelName,
  textarea,
  labelNameErr,
  labelNameSucc,
  textareaErr,
  textareaSucc,
};
