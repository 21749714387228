import { useStaticQuery, graphql } from 'gatsby';

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          defaultTitle: title
          defaultDescription: description
          siteUrl
        }
      }
    }
  `);

  const { defaultTitle, defaultDescription, siteUrl } = site.siteMetadata;

  return { defaultTitle, defaultDescription, siteUrl };
};
