import { css } from '@emotion/react';
import { grid } from '@prototyp/gatsby-plugin-gumball/utils';
import { breakpoints, colors } from 'style';

const root = css`
  ${grid.colSpan.c12}
  ${grid.colStart.c1}
  width: 100%;

  @media ${breakpoints.large} {
    grid-column: 5 / 13;
  }
`;

const wrapper = css`
  margin-bottom: calc(var(--unit) * 12);
  width: 100%;
  transition: 0.3s linear;

  @media ${breakpoints.large} {
    margin-bottom: calc(var(--unit) * 12);
    display: flex;
  }

  &:hover {
    transform: translateY(-8px);
  }
`;

const title = css`
  font-family: var(--font-family-primary);
  font-size: calc(var(--unit) * 9);
  line-height: calc(var(--unit) * 9);
  margin-bottom: var(--spacing-huge);

  ${grid.colSpan.c6}
  ${grid.colStart.c4}
`;

const img = css`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const imgWrapper = css`
  cursor: pointer;
  width: 100%;
  margin-bottom: var(--spacing-xxlrg);
  height: calc(var(--unit) * 80);

  @media ${breakpoints.medium} {
    width: 100%;
    height: calc(var(--unit) * 125);
  }

  @media ${breakpoints.large} {
    margin-right: var(--spacing-xxlrg);
    margin-bottom: 0;
    width: calc(var(--unit) * 46);
    height: calc(var(--unit) * 46);
  }
`;

const contentWrapper = css`
  position: relative;
  flex: 1;
  width: 100%;

  @media ${breakpoints.mediumMax} {
    margin-bottom: calc(var(--unit) * 16);
  }
`;

const category = css`
  font-family: var(--font-family-secondary);
  font-size: 14px;
  line-height: calc(var(--unit) * 4);
  letter-spacing: calc(var(--unit) * 0.5);
  color: ${colors.primary};
  text-transform: uppercase;
  margin-bottom: calc(var(--unit) * 2);
`;

const name = css`
  color: ${colors.text};
  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-custom500);
  font-size: var(--font-size-large);
  line-height: var(--line-height-medium);
  margin-bottom: calc(var(--unit) * 2);
`;

const info = css`
  color: ${colors.textLight};
  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  margin-bottom: var(--unit);
`;

const description = css`
  color: ${colors.textLight};
  font-family: var(--font-family-secondary);
  font-size: calc(var(--unit) * 4);
  line-height: calc(var(--unit) * 6);
  letter-spacing: -1px;
  margin-bottom: var(--spacing-xxlrg);
`;

const label = css`
  color: ${colors.border};
  font-family: var(--font-family-secondary);
  font-size: calc(var(--unit) * 3);
  line-height: 14px;
  margin-bottom: var(--unit);
`;

const button = css`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: 0;
  width: calc(var(--unit) * 50);
  padding: calc(var(--unit) * 2);

  color: ${colors.primary};
  fill: ${colors.primary};
  font-family: var(--font-family-secondary);
  font-weight: 500;
  font-size: calc(var(--unit) * 4);
  line-height: calc(var(--unit) * 6);
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.2s ease-out;

  @media ${breakpoints.smallMax} {
    display: none;
  }

  &:hover {
    color: ${colors.secondary};
    fill: ${colors.secondary};
  }
`;

const showMore = css`
  margin-right: var(--spacing-lrg);
  padding: var(--spacing-lrg) 0;
  transition: all 0.3s ease-out;

  &:hover {
    margin-right: var(--spacing-xxxlrg);
  }
`;

const arrowWrapper = css`
  margin-left: 0;
  padding: var(--spacing-lrg) 0;
  transition: all 0.3s ease-out;

  &:hover {
    margin-left: var(--spacing-med);
  }
`;

export const styles = {
  root,
  wrapper,
  title,
  img,
  imgWrapper,
  contentWrapper,
  category,
  name,
  info,
  description,
  label,
  button,
  showMore,
  arrowWrapper,
};
