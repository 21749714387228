import React from 'react';
import { checkboxStyles } from './Checkbox.styles';

interface Props extends React.HTMLProps<HTMLInputElement> {
  paragraph: string;
  name: string;
}

export const Checkbox: React.FC<Props> = ({ paragraph, name, ...props }) => {
  return (
    <div css={checkboxStyles.wrapper}>
      <input
        css={checkboxStyles.checkbox}
        type="checkbox"
        name={name}
        {...props}
      />
      <p css={checkboxStyles.description}>{paragraph}</p>
    </div>
  );
};
