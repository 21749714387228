import { Grid, ProgramItem, Skeleton } from 'components';
import { motion } from 'framer-motion';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { list, programActions, programSelector } from 'modules/program';
import { Aside } from './Aside';

interface Props {
  documents: ProgramItem[];
}

function filterProgram(
  selectedCategories: Record<ProgramCategory, boolean>,
  initialList: ProgramItem[],
) {
  const checkedCategories = Object.entries(selectedCategories).reduce(
    (acc: string[], [key, value]) => {
      if (value) return [...acc, key];

      return acc;
    },
    [],
  );

  if (!initialList) return;

  const filteredCategories = checkedCategories.length
    ? initialList.filter((item) =>
        checkedCategories.includes(item.node.package_type),
      )
    : initialList;

  return filteredCategories;
}

export const ProgramList: React.FC<Props> = ({ documents }) => {
  const { categories } = useSelector(programSelector.getState);
  const dispatch = useDispatch();

  const filteredDocuments = useMemo(
    () => filterProgram(categories, documents),
    [categories, documents],
  );

  function handleChange(value: boolean, section: ProgramCategory) {
    if (!section) return;

    dispatch(programActions.setCategory({ ...categories, [section]: value }));
  }

  if (!documents)
    return (
      <section>
        <Grid type="page" css={list.wrapper}>
          <Aside categories={categories} handleChange={handleChange} />
          <section css={list.skeletonWrapper}>
            {[...Array(6)].map((_, index) => (
              <Skeleton key={index} cardType="program" />
            ))}
          </section>
        </Grid>
      </section>
    );

  return (
    <section>
      <Grid type="page" css={list.wrapper}>
        <Aside categories={categories} handleChange={handleChange} />
        {filteredDocuments?.length ? (
          <ProgramItem documents={filteredDocuments} />
        ) : (
          <motion.p
            css={list.message}
            initial={{ opacity: 0, y: '20px' }}
            animate={{ opacity: 1, y: '0' }}
            transition={{ ease: 'easeIn', duration: 0.3 }}
          >
            <FormattedMessage id="packages.list.error" />
          </motion.p>
        )}
      </Grid>
    </section>
  );
};
