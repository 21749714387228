import { css } from '@emotion/react';
import { grid } from '@prototyp/gatsby-plugin-gumball/utils';
import { colors, fontFamily } from 'style';

const contentWrapper = css`
  ${grid.colSpan.c6}
  ${grid.colStart.c4}
  padding-top: calc(var(--unit) * 12);
  padding-bottom: calc(var(--unit) * 22);

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${colors.text};
    font-family: ${fontFamily.primary};
    font-size: calc(var(--unit) * 9);
    line-height: calc(var(--unit) * 9);
    margin-bottom: var(--spacing-huge);
  }

  & p {
    color: ${colors.textLight};
    font-family: ${fontFamily.secondary};
    font-size: calc(var(--unit) * 4);
    line-height: calc(var(--unit) * 6);
    margin-bottom: var(--spacing-xxlrg);
  }

  & > p > a {
    color: ${colors.text};
    font-weight: var(--font-weight-bold);
    text-decoration: underline;
  }

  & > p > img {
    object-fit: cover;
    margin-bottom: var(--spacing-xxxlrg);
  }
`;

export const details = {
  contentWrapper,
};
