import { GumGridConfig } from '@prototyp/gatsby-plugin-gumball/models';
import 'style/global.styles';

/**
 * Unitless unit value (in px)
 */

export const unit = 4;

/**
 * Color HEX values (to be converted to HSL)
 */
export const colors = {
  black: '#000000',
  white: '#FFFFFF',
  primary: '#098492',
  secondary: '#0cafc2',
  tertiary: '#F77F00',
  text: '#242424',
  textLight: '#5B5B5B',
  succes: '#259A51',
  error: '#C32424',
  noted: '#E9AD39',
  backgroundLight: '#F5F5F5',
  backgroundDark: '#F2F2F2',
  border: '#B9B9B9',
  borderLight: '#D4D4D4',
  borderDark: '#727272',
};

export const customPalette = {};

/**
 * Unitless px value pairs [font-size, line-height]
 */

export const typography = {
  tiny: [12, 16],
  small: [14, 20],
  base: [16, 24],
  medium: [20, 28],
  large: [24, 32],
  xLarge: [32, 36],
  xxLarge: [36, 40],
  xxxLarge: [48, 56],
  huge: [66, 70],
};

export const fontSize = {
  tiny: '12px',
  small: '14px',
  base: '16px',
  medium: '20px',
  large: '24px',
  xLarge: '32px',
  xxLarge: '36px',
  xxxLarge: '48px',
  huge: '66px',
};

export const lineHeight = {
  tiny: '16px',
  small: '20px',
  base: '24px',
  medium: '28px',
  large: '32px',
  xLarge: '36px',
  xxLarge: '40px',
  xxxLarge: '48px',
  huge: '70px',
};

/**
 * Minimum and maximum container widths, userd for fluid typography.
 * Max value is the total width of the page design (outer width of the page).
 */
export const minContainerWidth = 320;
export const maxContainerWidth = 1600;

export const fontWeight = {
  regular: 400,
  custom500: 500,
  bold: 700,
};

export const borderRadius = {
  small: '4px',
  regular: '8px',
  full: '50%',
};

export const fontFamily = {
  primary: 'Playfair Display, serif',
  secondary: 'Inter, sans-serif',
};

/**
 * Unitless z-index values, ordered from low to high
 */
export const zIndices = [-1, 0, 1, 2, 3];

/**
 * Breakpoint values without @media tags
 */

export const breakpoints = {
  tiny: 'screen and (min-width: 320px)',
  smallMax: 'screen and (max-width: 768px)',
  medium: 'screen and (min-width: 768px)',
  mediumMax: 'screen and (max-width: 1280px)',
  custom800: 'screen and (min-width: 800px)',
  large: 'screen and (min-width: 1280px)',
  xLarge: 'screen and (min-width: 1920px)',
  print: 'print',
};

/**
 * Wrappers
 */

export const wrappers = {
  tny: '320px',
  sml: '568px',
  med: '768px',
  large: '1280px',
  page: '1440px',
};

/**
 * Spacing - margins, paddings, grid gaps
 */
export const spacing = {
  tny: 'calc(var(--unit) * 1)',
  sml: 'calc(var(--unit) * 2)',
  med: 'calc(var(--unit) * 3)',
  lrg: 'calc(var(--unit) * 4)',
  customLrg: '22px',
  xlrg: 'calc(var(--unit) * 5)',
  xxlrg: 'calc(var(--unit) * 6)',
  xxxlrg: 'calc(var(--unit) * 7)',
  huge: 'calc(var(--unit) * 8)',
  xhuge: 'calc(var(--unit) * 9)',
  xxhuge: 'calc(var(--unit) * 10)',
};

/**
 * Main grid - page layout
 */

export const pageGrid: GumGridConfig[] = [
  {
    mediaMinWidth: 'medium',
    cols: 12,
    gap: { column: 40 },
  },
];

/**
 * Additional grids - component
 */
export const componentGrids = {
  cardGrid: [
    {
      cols: 2,
      gap: { column: 12, row: 12 },
    },
    {
      mediaMinWidth: 'medium',
      cols: 3,
      gap: { row: 16, column: 16 },
    },
    {
      mediaMinWidth: 'large',
      cols: 4,
      gap: { row: 12, column: 12 },
    },
  ],

  cardLayout: [
    {
      template: { row: '300px auto' },
      gap: { row: 24 },
    },
    {
      mediaMinWidth: 'medium',
      template: { column: '300px auto' },
      gap: { column: 32 },
    },
  ],
};
