import { css } from '@emotion/react';
import { colors } from 'style';

const wrapper = css`
  display: flex;

  margin-bottom: calc(var(--unit) * 9);
`;

const checkbox = css`
  width: calc(var(--unit) * 16);
  height: calc(var(--unit) * 5);

  margin-right: var(--spacing-med);
  background-color: ${colors.primary};
  cursor: pointer;
`;

const description = css`
  font-family: var(--font-family-secondary);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-small);
`;

export const checkboxStyles = { wrapper, checkbox, description };
