import { css } from '@emotion/react';
import { breakpoints, colors } from 'style';

const wrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
  width: 100%;

  @media ${breakpoints.medium} {
    width: 70%;
  }

  .image-gallery {
    position: static;
    ${breakpoints.medium} {
      width: 100%;
    }
  }

  .image-gallery-content {
    position: static;
  }

  .image-gallery-slide-wrapper {
    position: static;
  }

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 100%;
    width: 100%;
    max-height: calc(100vh - var(--unit) * 30);
  }

  .image-gallery-slide img {
    width: 100%;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
  }

  .image-gallery-index {
    position: absolute;
    bottom: var(--spacing-lrg);
    top: auto;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    font-family: var(--font-family-secondary);

    @media ${breakpoints.medium} {
      bottom: 1vh;
    }
  }

  .image-gallery-right-nav {
    right: var(--spacing-xlrg);
    bottom: var(--spacing-med);
    top: auto;

    z-index: 1;
    padding: 0;
    transform: translateY(0%);

    &:hover polyline {
      color: ${colors.borderLight};
    }

    @media ${breakpoints.medium} {
      right: calc(var(--unit) * 17);
      bottom: auto;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .image-gallery-left-nav {
    position: absolute;

    left: var(--spacing-xlrg);
    bottom: var(--spacing-med);
    top: auto;
    padding: 0;

    &:hover polyline {
      color: ${colors.borderLight};
    }

    z-index: 1;
    transform: translateY(0%);

    @media ${breakpoints.medium} {
      left: calc(var(--unit) * 17);
      bottom: auto;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

const overlay = css`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

  background: ${colors.black};
`;

const overlayHidden = css`
  display: none;
  max-height: 0px;
  transition: max-height 0.5s cubic-bezier(0, 1, 0.76, 0.98);
  overflow: hidden;
`;

const button = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

export const galleryStyles = {
  wrapper,
  overlay,
  overlayHidden,
  button,
};
