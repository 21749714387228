import { css } from '@emotion/react';
import { breakpoints, fontFamily, colors } from 'style';

const wrapper = css`
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: ${fontFamily.secondary};
  font-weight: 400;
  transition: 0.4s ease-out;
  margin-bottom: calc(var(--unit) * 12);

  @media ${breakpoints.medium} {
    margin-bottom: calc(var(--unit) * 18);
  }

  &:hover {
    transform: translateY(-10px);
  }
`;

const imageWrapper = css`
  width: 100%;
  margin-bottom: var(--spacing-xlrg);
  transition: 0.3s ease-out;
  height: 180px;

  @media ${breakpoints.medium} {
    height: 290px;
    margin-bottom: var(--spacing-xlrg);
  }
`;

const image = css`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media ${breakpoints.medium} {
    height: 290px;
  }
`;

const categoryName = css`
  font-size: 14px;
  line-height: var(--line-height-tiny);
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: var(--spacing-tny);
  color: ${colors.primary};

  @media ${breakpoints.medium} {
    margin-bottom: var(--spacing-med);
  }
`;

const title = css`
  color: ${colors.text};
  font-family: ${fontFamily.primary};
  font-size: var(--font-size-xLarge);
  line-height: var(--line-height-xxLarge);
  margin-bottom: var(--spacing-med);
  font-weight: var(--font-weight-regular);

  width: fit-content;
  position: relative;

  @media ${breakpoints.medium} {
    font-family: ${fontFamily.secondary};
    font-size: var(--font-size-large);
    line-height: var(--line-height-medium);
  }
`;

const description = css`
  overflow: hidden;
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  color: ${colors.textLight};
`;

const descriptionSecondary = css`
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  color: ${colors.textLight};
  display: -webkit-box;
  -webkit-box-orient: vertical; /*stylelint-disable-line property-no-vendor-prefix */
  -moz-box-orient: vertical; /*stylelint-disable-line property-no-vendor-prefix*/
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  -webkit-line-clamp: 3;
`;

const anchor = css`
  position: absolute;
  height: 100%;
  width: 100%;
`;

const skeleton = css`
  animation: skeleton-loading 1s linear infinite alternate;

  @keyframes skeleton-loading {
    0% {
      background-color: ${colors.border};
    }

    100% {
      background-color: ${colors.backgroundLight};
    }
  }
`;

const skeletonImage = css`
  ${skeleton}

  width: 100%;
  margin-bottom: var(--spacing-xlrg);
  height: 180px;

  @media ${breakpoints.medium} {
    height: 290px;
    margin-bottom: var(--spacing-xlrg);
  }
`;

const skeletonDescription = css`
  ${skeleton}
  height: 2em;
  width: 80%;
  color: ${colors.textLight};
`;

const skeletonTitle = css`
  ${skeleton}

  width: 70%;
  height: 1em;
  position: relative;
  margin-bottom: var(--spacing-med);
`;

const skeletonCategoryName = css`
  ${skeleton}
  width: 30%;
  height: 1em;
  margin-bottom: var(--spacing-tny);

  @media ${breakpoints.medium} {
    margin-bottom: var(--spacing-med);
  }
`;

export const cardStyles = {
  title,
  wrapper,
  image,
  imageWrapper,
  anchor,
  description,
  descriptionSecondary,
  categoryName,
  skeleton,
  skeletonImage,
  skeletonDescription,
  skeletonTitle,
  skeletonCategoryName,
};
