import { flex } from '@prototyp/gatsby-plugin-gumball/utils';
import Arrow from 'assets/icons/arrowRightGreen.svg';
import { motion } from 'framer-motion';
import { FormattedMessage, Link } from 'gatsby-plugin-react-intl';
import React from 'react';
import InView from 'react-intersection-observer';
import { styles } from './ProgramItem.styles';
import { ProgramItemImage } from './ProgramItemImage';

interface Props {
  isRecommendedItem?: boolean;
  documents?: ProgramItem[];
}

export const ProgramItem: React.FC<Props> = ({ documents }) => {
  return (
    <div css={styles.root}>
      {documents?.map((item, index) => (
        <InView
          threshold={0.4}
          triggerOnce={true}
          key={item.node.title[0].text}
        >
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0, y: '40px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '40px' }}
              transition={{
                ease: 'easeOut',
                duration: 0.5,
                delay: index < 4 ? index / 10 : 0.2,
              }}
            >
              <Link to={`/program/${item.node._meta.uid}`} css={styles.wrapper}>
                <div css={styles.imgWrapper}>
                  <ProgramItemImage item={item} />
                </div>
                <div css={styles.contentWrapper}>
                  <p css={styles.category}>
                    /{' '}
                    <FormattedMessage
                      id={`categories.${item.node.package_type.toLowerCase()}`}
                    />
                  </p>

                  <div
                    css={[
                      flex.display.flex,
                      flex.justify.between,
                      flex.align.start,
                      flex.direction.column,
                    ]}
                  >
                    <h3 css={styles.name}>{item.node.title[0].text}</h3>
                    <p css={styles.info}>
                      <FormattedMessage id="packages.list.item.duration" />:{' '}
                      {item.node.event_duration[0].text}
                    </p>
                    <p css={styles.info}>
                      <FormattedMessage id="packages.list.item.age" />:{' '}
                      {item.node.age_restrictions[0].text}
                    </p>
                    <p css={styles.info}>
                      <FormattedMessage id="packages.list.item.activity" />:{' '}
                      {item.node.challenge_level[0].text}
                    </p>
                    <button css={styles.button}>
                      <p css={styles.showMore}>
                        <FormattedMessage id="button.see-more" />
                      </p>
                      <div css={styles.arrowWrapper}>
                        <Arrow />
                      </div>
                    </button>
                  </div>
                </div>
              </Link>
            </motion.div>
          )}
        </InView>
      ))}
    </div>
  );
};
