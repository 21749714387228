import React, { useMemo } from 'react';
import { grid } from '@prototyp/gatsby-plugin-gumball/utils';
import { wrapper } from './Grid.styles';
import { Interpolation } from '@emotion/serialize';
import { Theme } from '@emotion/react';

interface Props {
  type: keyof typeof grid.config;
  css?: Interpolation<Theme>;
}

export const Grid: React.FC<Props> = ({ children, type, css, ...rest }) => {
  const gridConfig = useMemo(() => grid.config[type], [type]);

  return (
    <div css={[gridConfig, wrapper, css]} {...rest}>
      {children}
    </div>
  );
};
