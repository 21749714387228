import { css } from '@emotion/react';
import { colors } from 'style';

const loader = css`
  border: calc(var(--unit) * 2) solid ${colors.backgroundLight};
  border-radius: 50%;
  border-top: calc(var(--unit) * 2) solid ${colors.primary};
  width: calc(var(--unit) * 10);
  height: calc(var(--unit) * 10);

  animation: spin 2s linear infinite;
  margin: 60px auto;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

const loadingWrapper = css`
  padding: calc(var(--unit) * 50) 0;
`;

export const loadingStyles = {
  loader,
  loadingWrapper,
};
