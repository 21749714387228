import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-react-intl';
import { usePrismicImages } from 'hooks';
import { RichText } from 'prismic-reactjs';
import React from 'react';
import { cardStyles } from './Card.styles';

interface Props {
  document: PrismicDocument;
  cardType: 'blog' | 'destinations';
}

interface QueryType {
  edges: [
    {
      node: {
        data: {
          image: IGatsbyImageData;
        };
        uid: string;
      };
    },
  ];
}

export const Card: React.FC<Props> = ({ document, cardType }) => {
  const { formatMessage } = useIntl();
  const { blogPostsQuery, destinationsQuery } = usePrismicImages();

  function getMatchingNode(query: QueryType) {
    return query.edges.find((edge) => edge.node.uid === document.node._meta.uid)
      ?.node.data.image;
  }

  let image;
  if (cardType === 'blog') image = getMatchingNode(blogPostsQuery);
  if (cardType === 'destinations') image = getMatchingNode(destinationsQuery);

  const imageData = image && getImage(image);

  return (
    <div css={cardStyles.wrapper}>
      {imageData && image ? (
        <div css={cardStyles.imageWrapper}>
          <GatsbyImage
            loading="lazy"
            image={imageData}
            css={cardStyles.image}
            alt={document?.node.image.alt || ''}
          />
        </div>
      ) : (
        <div css={cardStyles.imageWrapper}>
          <img
            loading="lazy"
            css={cardStyles.image}
            src={document?.node.image.url}
            alt={document?.node.image.alt || ''}
          />
        </div>
      )}
      {document?.node.category_name && (
        <p css={cardStyles.categoryName}>
          /{' '}
          {formatMessage({
            id: `categories.${document.node.category_name.toLowerCase()}`,
          })}
        </p>
      )}
      <h2 css={cardStyles.title}>{document?.node.title[0].text}</h2>
      {document?.node.summary && (
        <div css={cardStyles.description}>
          <RichText render={document.node.summary} />
        </div>
      )}
    </div>
  );
};
