import React from 'react';
import { RouteComponentProps, useParams } from '@reach/router';
import { DetailsHeader } from 'components/details-item/DetailsHeader';
import { DetailsItem, SeoTags } from 'components';
import { RichText } from 'prismic-reactjs';
import { Loading, loadingStyles } from 'components/loading';

interface Props extends RouteComponentProps {
  documents: PrismicDocument[];
}

export const DestinationItem: React.FC<Props> = ({ documents }) => {
  const { uid } = useParams();
  const item = documents?.find((item) => item.node._meta.uid === uid);
  const descriptionLength =
    item?.node.content && item?.node.content[0].text.length;

  function getDescription(item?: PrismicDocument) {
    if (item?.node.content && descriptionLength > 150)
      return item?.node.content[0].text.slice(0, 151) + '...';

    if (item?.node.content && descriptionLength <= 150)
      return item?.node.content[0].text;

    return undefined;
  }

  const description = getDescription(item);

  if (!item) {
    return (
      <div css={loadingStyles.loadingWrapper}>
        <Loading />;
      </div>
    );
  }

  return (
    <>
      <SeoTags
        title={`Panona - ${item?.node.title[0].text}`}
        description={description}
        imagePath={item?.node.image.url}
      />
      {item && <DetailsHeader document={item} path="destinations" />}
      <DetailsItem>
        <RichText render={item?.node.content} />
      </DetailsItem>
    </>
  );
};
