import { Grid } from 'components';
import React from 'react';
import ArrowRight from 'assets/icons/arrowRight.svg';
import { destinationsHeader } from './DestinationsHeader.styles';
import { motion } from 'framer-motion';
import { FormattedMessage, Link } from 'gatsby-plugin-react-intl';

interface Props {
  title: string;
}

export const DestinationsHeader: React.FC<Props> = ({ title }) => {
  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: 'easeOut', duration: 0.6 }}
      css={destinationsHeader.root}
    >
      <Grid type="page">
        <h1 css={destinationsHeader.title}>{title}</h1>
        <div css={destinationsHeader.descriptionWrapper}>
          <Link to="/" css={destinationsHeader.link}>
            <FormattedMessage id="breadcrumb.home" />
          </Link>
          <ArrowRight css={destinationsHeader.arrow} />
          <p css={destinationsHeader.description}>{title}</p>
        </div>
      </Grid>
    </motion.section>
  );
};
