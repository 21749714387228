import { useStaticQuery, graphql } from 'gatsby';

export const usePrismicImages = () => {
  const { blogPostsQuery, destinationsQuery, programQuery } =
    useStaticQuery(graphql`
      query {
        blogPostsQuery: allPrismicBlogPost {
          edges {
            node {
              data {
                image {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              uid
            }
          }
        }
        destinationsQuery: allPrismicDestination {
          edges {
            node {
              data {
                image {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              uid
            }
          }
        }
        programQuery: allPrismicProgram {
          edges {
            node {
              data {
                program_images {
                  image {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
              uid
            }
          }
        }
      }
    `);

  return { blogPostsQuery, destinationsQuery, programQuery };
};
