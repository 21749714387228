import { css } from '@emotion/react';
import { breakpoints, colors } from 'style';

const wrapperPrimary = css`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: calc(var(--unit) * 12);

  @media ${breakpoints.medium} {
    margin-bottom: calc(var(--unit) * 18);
  }
`;

const wrapperSecondary = css`
  width: 100%;
  margin-bottom: calc(var(--unit) * 12);

  @media ${breakpoints.large} {
    display: flex;
  }
`;

const wrapperTertiary = css`
  position: relative;
  flex: 1;
  width: 100%;

  @media ${breakpoints.mediumMax} {
    margin-bottom: calc(var(--unit) * 16);
  }
`;

const skeleton = css`
  animation: skeleton-loading 1s linear infinite alternate;

  @keyframes skeleton-loading {
    0% {
      background-color: ${colors.border};
    }

    100% {
      background-color: ${colors.backgroundLight};
    }
  }
`;

const skeletonImagePrimary = css`
  ${skeleton}

  width: 100%;
  margin-bottom: var(--spacing-xlrg);
  height: calc(var(--unit) * 40);

  @media ${breakpoints.medium} {
    height: calc(var(--unit) * 72 + 2px);
    margin-bottom: var(--spacing-xlrg);
  }
`;

const skeletonImageSecondary = css`
  ${skeleton}

  width: 100%;
  margin-bottom: var(--spacing-xxlrg);
  height: calc(var(--unit) * 80);

  @media ${breakpoints.medium} {
    width: 100%;
    height: calc(var(--unit) * 125);
  }

  @media ${breakpoints.large} {
    margin-right: var(--spacing-xxlrg);
    margin-bottom: 0;
    width: calc(var(--unit) * 46);
    height: calc(var(--unit) * 46);
  }
`;

const skeletonDescriptionPrimary = css`
  ${skeleton}
  height: calc(var(--unit) * 4);
  width: 90%;
  color: ${colors.textLight};
  margin-bottom: var(--spacing-tny);

  border-radius: calc(var(--unit) * 2);
`;

const skeletonDescriptionSecondary = css`
  ${skeleton}
  height: calc(var(--unit) * 4);
  width: 70%;
  color: ${colors.textLight};

  border-radius: calc(var(--unit) * 2);
`;

const skeletonTitlePrimary = css`
  ${skeleton}

  width: 60%;
  height: calc(var(--unit) * 8);
  position: relative;
  margin-bottom: var(--spacing-med);

  border-radius: calc(var(--unit) * 3);

  @media ${breakpoints.medium} {
    height: calc(var(--unit) * 6);
  }
`;

const skeletonTitleSecondary = css`
  ${skeleton}

  width: 42%;
  height: calc(var(--unit) * 7);
  margin-bottom: var(--spacing-lrg);

  border-radius: calc(var(--unit) * 2);

  @media ${breakpoints.medium} {
    width: 35%;
  }
`;

const skeletonCategoryNamePrimary = css`
  ${skeleton}

  width: 30%;
  height: calc(var(--unit) * 4 - 2px);
  margin-bottom: var(--spacing-tny);

  border-radius: calc(var(--unit) * 2);

  @media ${breakpoints.medium} {
    margin-bottom: var(--spacing-med);
  }
`;

const skeletonCategoryNameSecondary = css`
  ${skeleton}

  width: 30%;
  height: calc(var(--unit) * 4 - 2px);
  margin-bottom: var(--spacing-med);

  border-radius: calc(var(--unit) * 2);

  @media ${breakpoints.medium} {
    width: 25%;
  }
`;

const skeletonInfo = css`
  ${skeleton}

  height: calc(var(--unit) * 4 - 2px);
  margin-bottom: var(--spacing-med);

  border-radius: calc(var(--unit) * 2);
`;

const skeletonInfoPrimary = css`
  ${skeletonInfo}

  width: 50%;

  @media ${breakpoints.medium} {
    width: 38%;
  }
`;

const skeletonInfoSecondary = css`
  ${skeletonInfo}

  width: 52%;

  @media ${breakpoints.medium} {
    width: 40%;
  }
`;

const skeletonInfoTertiary = css`
  ${skeletonInfo}

  width: 53%;

  @media ${breakpoints.medium} {
    width: 41%;
  }
`;

export const skeletonStyles = {
  wrapperPrimary,
  wrapperSecondary,
  wrapperTertiary,
  skeleton,
  skeletonImagePrimary,
  skeletonImageSecondary,
  skeletonDescriptionPrimary,
  skeletonDescriptionSecondary,
  skeletonTitlePrimary,
  skeletonTitleSecondary,
  skeletonCategoryNamePrimary,
  skeletonCategoryNameSecondary,
  skeletonInfoPrimary,
  skeletonInfoSecondary,
  skeletonInfoTertiary,
};
