import { css } from '@emotion/react';
import { breakpoints, colors } from 'style';

const root = css`
  grid-column: 3 / span 8;

  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-regular);
  margin-bottom: calc(var(--unit) * 22);

  @media ${breakpoints.medium} {
    margin-bottom: calc(var(--unit) * 20);
  }
`;

const title = css`
  font-family: var(--font-family-primary);
  font-size: var(--font-size-xLarge);
  line-height: var(--line-height-xxLarge);
  font-weight: var(--font-weight-regular);

  margin-bottom: var(--spacing-xxxlrg);

  @media ${breakpoints.medium} {
    font-size: var(--font-size-xxLarge);
    line-height: var(--line-height-xxxLarge);
    margin-bottom: var(--spacing-huge);
  }
`;

const list = css`
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  padding-left: var(--spacing-xlrg);

  color: ${colors.textLight};
`;

const listItem = css`
  margin-bottom: var(--spacing-med);
`;

export const importantInfoStyles = {
  root,
  title,
  list,
  listItem,
};
