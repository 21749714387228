import { css } from '@emotion/react';
import { breakpoints } from 'style';

const wrapper = css`
  min-height: 100vh;
`;

const message = css`
  grid-column: 2 / span 10;

  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);

  margin-bottom: var(--spacing-huge);
  padding: calc(var(--unit) * 3) 0 calc(var(--unit) * 20);

  @media ${breakpoints.medium} {
    font-size: var(--font-size-large);
    line-height: var(--line-height-large);
  }
`;

export const contentListStyles = {
  message,
  wrapper,
};
