import { css } from '@emotion/react';
import { grid } from '@prototyp/gatsby-plugin-gumball/utils';
import { breakpoints, colors, fontFamily } from 'style';

const root = css`
  padding-top: calc(var(--unit) * 22);
  padding-bottom: calc(var(--unit) * 8);

  @media ${breakpoints.medium} {
    padding-bottom: calc(var(--unit) * 12);
  }
`;

const title = css`
  font-family: ${fontFamily.primary};
  font-size: calc(var(--unit) * 9);
  font-weight: var(--font-weight-regular);
  line-height: calc(var(--unit) * 12);
  margin-bottom: var(--spacing-lrg);
  ${grid.colSpan.c2}
  ${grid.colStart.c2}

  @media ${breakpoints.large} {
    margin-bottom: var(--spacing-xxxlrg);
    font-size: 66px;
    line-height: 70px;
  }
`;

const arrow = css`
  margin: 0 var(--spacing-xlrg);
`;

const descriptionWrapper = css`
  font-family: ${fontFamily.secondary};
  font-size: calc(var(--unit) * 3) + 2;
  line-height: calc(var(--unit) * 5);
  margin-bottom: calc(var(--unit) * 15);
  display: flex;
  align-items: center;
  ${grid.colSpan.c5}
  ${grid.colStart.c2}

  @media ${breakpoints.medium} {
    margin-bottom: calc(var(--unit) * 10);
  }
`;

const description = css`
  font-family: ${fontFamily.secondary};
  font-size: calc(var(--unit) * 3) + 2;
  line-height: calc(var(--unit) * 5);
`;

const link = css`
  ${description}
  color: ${colors.primary};
  padding: var(--spacing-sml) 0;
  width: fit-content;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 4px;
    left: 0;
    background-color: ${colors.secondary};
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    color: ${colors.secondary};
  }

  &:hover:before {
    visibility: visible;
    width: 100%;
  }
`;

const filterWrapper = css`
  font-family: ${fontFamily.secondary};
  ${grid.colSpan.c11}
  ${grid.colStart.c2}
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  overflow-x: auto;
`;

const buttonActive = css`
  border: none;
  background-color: transparent;
  font-weight: var(--font-weight-bold);
  font-size: 14px;
  color: ${colors.text};
  cursor: pointer;
  padding: var(--spacing-sml) 0;
  margin: 0 var(--spacing-med);
  position: relative;
  width: fit-content;
  border-bottom: 1px solid ${colors.primary};
`;

const button = css`
  ${buttonActive}
  color: ${colors.border};
  font-weight: var(--font-weight-regular);
  border: none;

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: ${colors.primary};
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  &:hover:before {
    visibility: visible;
    width: 100%;
  }
`;

export const destinationsHeader = {
  root,
  title,
  descriptionWrapper,
  description,
  link,
  arrow,
  filterWrapper,
  button,
  buttonActive,
};
