import { blogActions } from './actions';

interface ReducerState {
  readonly category?: BlogCategory;
}

const INITIAL_STATE: ReducerState = {
  category: 'All',
};

export const blogReducer = (
  state: ReducerState = INITIAL_STATE,
  action: ActionUnion<typeof blogActions>,
): ReducerState => {
  switch (action.type) {
    case 'blog/SET_CATEGORY':
      return {
        ...state,
        category: action.payload.category,
      };

    default:
      return state || INITIAL_STATE;
  }
};
