import { css } from '@emotion/react';
import { grid } from '@prototyp/gatsby-plugin-gumball/utils';
import { breakpoints } from 'style';

const wrapper = css`
  margin-bottom: calc(var(--unit) * 40);
`;

const contentWrapper = css`
  padding: var(--spacing-huge) var(--spacing-xxlrg);
`;

const skeletonWrapper = css`
  ${grid.colSpan.c12}
  ${grid.colStart.c1}
  width: 100%;

  @media ${breakpoints.large} {
    grid-column: 5 / 13;
  }
`;

const message = css`
  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-large);
  line-height: var(--line-height-large);

  margin-bottom: var(--spacing-huge);

  @media ${breakpoints.medium} {
    grid-column: 1 / span 10;
    margin-bottom: 0;
  }

  @media ${breakpoints.large} {
    grid-column: 5 / span 6;
    font-size: var(--font-size-xLarge);
    line-height: var(--line-height-xLarge);
  }
`;

export const list = {
  wrapper,
  contentWrapper,
  message,
  skeletonWrapper,
};
