import { css } from '@emotion/react';
import { colors } from './variables';
import InterRegular from 'assets/fonts/Inter-Regular.woff';
import InterRegularWoff2 from 'assets/fonts/Inter-Regular.woff2';
import InterMedium from 'assets/fonts/Inter-Medium.ttf';
import InterSemiBold from 'assets/fonts/Inter-SemiBold.ttf';
import InterBold from 'assets/fonts/Inter-Bold.woff';
import InterBoldWoff2 from 'assets/fonts/Inter-Bold.woff2';
import InterItalic from 'assets/fonts/Inter-Italic.woff';
import InterItalicWoff2 from 'assets/fonts/Inter-Italic.woff2';
import InterBoldItalic from 'assets/fonts/Inter-BoldItalic.woff';
import InterBoldItalicWoff2 from 'assets/fonts/Inter-BoldItalic.woff2';

import PlayfairDisplayRegular from 'assets/fonts/PlayfairDisplay-Regular.woff';
import PlayfairDisplayRegularWoff2 from 'assets/fonts/PlayfairDisplay-Regular.woff2';
import PlayfairDisplayBold from 'assets/fonts/PlayfairDisplay-Bold.woff';
import PlayfairDisplayBoldWoff2 from 'assets/fonts/PlayfairDisplay-Bold.woff2';
import PlayfairDisplayItalic from 'assets/fonts/PlayfairDisplay-Italic.woff';
import PlayfairDisplayItalicWoff2 from 'assets/fonts/PlayfairDisplay-Italic.woff2';
import PlayfairDisplayBoldItalic from 'assets/fonts/PlayfairDisplay-BoldItalic.woff';
import PlayfairDisplayBoldItalicWoff2 from 'assets/fonts/PlayfairDisplay-BoldItalic.woff2';

export const global = css`
  html {
    box-sizing: border-box;
  }

  body {
    min-height: 100vh;
    padding: 0;
    margin: 0;
    color: ${colors.text};
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    text-decoration: none;

    &:focus {
      outline: none;
    }
  }

  button {
    &:focus {
      outline: none;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul {
    margin: 0;
    padding: 0;
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 400;
    src: url(${InterRegular}) format('woff'),
      url(${InterRegularWoff2}) format('woff2'),
      url(${InterItalic}) format('woff'),
      url(${InterItalicWoff2}) format('woff2');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 500;
    src: url(${InterMedium}) format('woff');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 600;
    src: url(${InterSemiBold}) format('woff');
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 700;
    src: url(${InterBold}) format('woff'),
      url(${InterBoldWoff2}) format('woff2'),
      url(${InterBoldItalic}) format('woff'),
      url(${InterBoldItalicWoff2}) format('woff2');
  }

  @font-face {
    font-family: 'Playfair Display';
    font-weight: 400;
    src: url(${PlayfairDisplayRegular}) format('woff'),
      url(${PlayfairDisplayRegularWoff2}) format('woff2'),
      url(${PlayfairDisplayItalic}) format('woff'),
      url(${PlayfairDisplayItalicWoff2}) format('woff2');
  }

  @font-face {
    font-family: 'Playfair Display';
    font-weight: 700;
    src: url(${PlayfairDisplayBold}) format('woff'),
      url(${PlayfairDisplayBoldWoff2}) format('woff2'),
      url(${PlayfairDisplayBoldItalic}) format('woff'),
      url(${PlayfairDisplayBoldItalicWoff2}) format('woff2');
  }

  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    height: auto;
    -webkit-touch-callout: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
      outline: none;
    }

    &.dragging {
      cursor: pointer;
      cursor: hand;
    }
  }

  .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: auto;

    &:before,
    &:after {
      content: '';
      display: table;
    }

    &:after {
      clear: both;
    }

    .slick-loading & {
      visibility: hidden;
    }
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: translate3d(0, 0, 0);
  }

  .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;

    [dir='rtl'] & {
      float: right;
    }

    img {
      display: block;
    }

    &.slick-loading img {
      display: none;
    }
    display: none;

    &.dragging img {
      pointer-events: none;
    }

    .slick-initialized & {
      display: block;
    }

    .slick-loading & {
      visibility: hidden;
    }

    .slick-vertical & {
      display: block;
      height: auto;
      border: 1px solid transparent;
    }
  }

  .slick-arrow .slick-hidden {
    display: none;
  }
`;
