import { useStaticQuery, graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';

export const useImages = () => {
  const { formatMessage } = useIntl();
  const {
    logoGhost,
    logoGhostNav,
    logo,
    interregLogo,
    interregLogoGhost,
    navImage,
    aboutProject,
    headerImage,
    homeImage,
    aboutImage,
    destinationsMeta,
    contactMeta,
    blogMeta,
    paketiMeta,
    startUp,
    turistickaAgencija,
    evaluacija,
    dravaDunav,
    vodenaVrata,
    vinskiPodrumi,
    spomenKriz,
    spomenikFraLukiIbrisimovicu,
    saranICobanac,
    principovac,
    panonaFest2,
    osjeckaTvrda,
    osjeckaKatedrala,
    mastIPaprika,
    kulen,
    kulenovaSeka,
    kopackiRit,
    kopackiRit2,
    knezeviVinogradi,
    fisPaprikas,
    erdut,
    dvoracNaBrdu,
    dravskiMost,
    dakovackiVezovi,
    dakovackaKatedrala,
    crkvaGospeOdUtocista,
    baranja,
    vodeniSportovi,
    papuk,
    kopackiRitThree,
    vino,
  } = useStaticQuery(
    graphql`
      query {
        logoGhost: file(
          relativePath: { eq: "images/logoPanonaToursGhost.png" }
        ) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: TRACED_SVG)
          }
        }
        logoGhostNav: file(
          relativePath: { eq: "images/logoPanonaToursGhostNav.png" }
        ) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: TRACED_SVG)
          }
        }
        logo: file(relativePath: { eq: "images/logoPanonaTours.png" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: TRACED_SVG)
          }
        }
        interregLogo: file(relativePath: { eq: "images/interregLogo.png" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        interregLogoGhost: file(
          relativePath: { eq: "images/interregLogoGhost.png" }
        ) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        navImage: file(relativePath: { eq: "images/navigation.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        aboutProject: file(relativePath: { eq: "images/aboutProject.jpg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        headerImage: file(relativePath: { eq: "images/headerImage.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        homeImage: file(relativePath: { eq: "images/homeImageGarden.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        aboutImage: file(relativePath: { eq: "images/homeImageAbout.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        destinationsMeta: file(
          relativePath: { eq: "images/destinationsMeta.jpeg" }
        ) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        contactMeta: file(relativePath: { eq: "images/contactMeta.jpg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        blogMeta: file(relativePath: { eq: "images/blogMeta.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        paketiMeta: file(relativePath: { eq: "images/paketiMeta.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        startUp: file(relativePath: { eq: "images/startUp.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        turistickaAgencija: file(
          relativePath: { eq: "images/turistickaAgencija.jpeg" }
        ) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        evaluacija: file(relativePath: { eq: "images/evaluacija.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        dravaDunav: file(relativePath: { eq: "images/dravaDunav.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        vodenaVrata: file(relativePath: { eq: "images/vodenaVrata.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        vinskiPodrumi: file(relativePath: { eq: "images/vinskiPodrumi.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        spomenKriz: file(relativePath: { eq: "images/spomenKriz.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        spomenikFraLukiIbrisimovicu: file(
          relativePath: { eq: "images/spomenikFraLukiIbrisimovicu.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        saranICobanac: file(relativePath: { eq: "images/saranICobanac.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        principovac: file(relativePath: { eq: "images/principovac.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        panonaFest2: file(relativePath: { eq: "images/panonaFest2.jpg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        osjeckaTvrda: file(relativePath: { eq: "images/osjeckaTvrda.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        osjeckaKatedrala: file(
          relativePath: { eq: "images/osjeckaKatedrala.jpeg" }
        ) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        mastIPaprika: file(relativePath: { eq: "images/mastIPaprika.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        kulen: file(relativePath: { eq: "images/kulen.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        kulenovaSeka: file(relativePath: { eq: "images/kulenovaSeka.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        kopackiRit: file(relativePath: { eq: "images/kopackiRit.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        kopackiRit2: file(relativePath: { eq: "images/kopackiRit2.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        knezeviVinogradi: file(
          relativePath: { eq: "images/knezeviVinogradi.jpeg" }
        ) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        fisPaprikas: file(relativePath: { eq: "images/fisPaprikas.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        erdut: file(relativePath: { eq: "images/erdut.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        dvoracNaBrdu: file(relativePath: { eq: "images/dvoracNaBrdu.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        dravskiMost: file(relativePath: { eq: "images/dravskiMost.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        dakovackiVezovi: file(
          relativePath: { eq: "images/dakovackiVezovi.jpeg" }
        ) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        dakovackaKatedrala: file(
          relativePath: { eq: "images/dakovackaKatedrala.jpeg" }
        ) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        crkvaGospeOdUtocista: file(
          relativePath: { eq: "images/crkvaGospeOdUtocista.jpeg" }
        ) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        baranja: file(relativePath: { eq: "images/baranja.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        vodeniSportovi: file(
          relativePath: { eq: "images/vodeniSportovi.jpeg" }
        ) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        papuk: file(relativePath: { eq: "images/papuk.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        kopackiRitThree: file(
          relativePath: { eq: "images/kopackiRitThree.jpeg" }
        ) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
        vino: file(relativePath: { eq: "images/vino.jpeg" }) {
          childImageSharp {
            gatsbyImageData(formats: [PNG, WEBP], placeholder: BLURRED)
          }
        }
      }
    `,
  );

  const galleryContent: AboutImage[] = [
    {
      imageUrl: vodenaVrata,
      imageTitle: formatMessage({ id: 'images.titles.water-gates' }),
    },
    {
      imageUrl: vinskiPodrumi,
      imageTitle: formatMessage({ id: 'images.titles.wine-cellars' }),
    },
    {
      imageUrl: spomenKriz,
      imageTitle: formatMessage({ id: 'images.titles.memorial-cross' }),
    },
    {
      imageUrl: principovac,
      imageTitle: formatMessage({ id: 'images.titles.principovac' }),
    },
    {
      imageUrl: vino,
      imageTitle: formatMessage({ id: 'images.titles.local-wine' }),
    },
    {
      imageUrl: saranICobanac,
      imageTitle: formatMessage({ id: 'images.titles.carp-in-forks' }),
    },
    {
      imageUrl: spomenikFraLukiIbrisimovicu,
      imageTitle: formatMessage({ id: 'images.titles.statue-of-friar' }),
    },
    {
      imageUrl: panonaFest2,
      imageTitle: formatMessage({ id: 'images.titles.panona-fest' }),
    },
    {
      imageUrl: osjeckaTvrda,
      imageTitle: formatMessage({ id: 'images.titles.tvrda' }),
    },
    {
      imageUrl: osjeckaKatedrala,
      imageTitle: formatMessage({ id: 'images.titles.co-cathedral' }),
    },
    {
      imageUrl: mastIPaprika,
      imageTitle: formatMessage({ id: 'images.titles.lard' }),
    },
    {
      imageUrl: kulen,
      imageTitle: formatMessage({ id: 'images.titles.kulen' }),
    },
    {
      imageUrl: kulenovaSeka,
      imageTitle: formatMessage({ id: 'images.titles.kulenova-seka' }),
    },
    {
      imageUrl: kopackiRitThree,
      imageTitle: formatMessage({ id: 'images.titles.kopacki-rit' }),
    },
    {
      imageUrl: knezeviVinogradi,
      imageTitle: formatMessage({ id: 'images.titles.knezevi-vinogradi' }),
    },
    {
      imageUrl: kopackiRit2,
      imageTitle: formatMessage({ id: 'images.titles.kopacki-rit' }),
    },
    {
      imageUrl: fisPaprikas,
      imageTitle: formatMessage({ id: 'images.titles.fish' }),
    },
    {
      imageUrl: papuk,
      imageTitle: formatMessage({ id: 'images.titles.papuk' }),
    },
    {
      imageUrl: dvoracNaBrdu,
      imageTitle: formatMessage({ id: 'images.titles.erdut-fortress' }),
    },
    {
      imageUrl: dravskiMost,
      imageTitle: formatMessage({
        id: 'images.titles.pedestrian-bridge',
      }),
    },
    {
      imageUrl: dakovackiVezovi,
      imageTitle: formatMessage({ id: 'images.titles.dakovacki-vezovi' }),
    },
    {
      imageUrl: dakovackaKatedrala,
      imageTitle: formatMessage({ id: 'images.titles.cathedral' }),
    },
    {
      imageUrl: vodeniSportovi,
      imageTitle: formatMessage({ id: 'images.titles.water-sports' }),
    },
    {
      imageUrl: crkvaGospeOdUtocista,
      imageTitle: formatMessage({ id: 'images.titles.church' }),
    },
    {
      imageUrl: baranja,
      imageTitle: formatMessage({ id: 'images.titles.baranya' }),
    },
  ];

  return {
    logoGhost,
    logoGhostNav,
    logo,
    interregLogo,
    interregLogoGhost,
    navImage,
    aboutProject,
    headerImage,
    homeImage,
    aboutImage,
    destinationsMeta,
    contactMeta,
    blogMeta,
    paketiMeta,
    startUp,
    turistickaAgencija,
    evaluacija,
    kopackiRit,
    dravaDunav,
    erdut,
    vodenaVrata,
    panonaFest2,
    dakovackiVezovi,
    galleryContent,
  };
};
