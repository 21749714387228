import { useQuery } from '@apollo/client';
import { GET_PROGRAMS } from 'queries';

export const useProgramDocuments = (locale: string) => {
  let lang = locale;
  if (locale === 'en') lang = 'en-eu';
  if (locale === 'de') lang = 'de-de';
  if (locale === 'sr') lang = 'rs';

  const documents: ProgramItem[] = useQuery(GET_PROGRAMS, {
    variables: { lang },
  }).data?.allPrograms?.edges;

  const orderedDocuments =
    documents &&
    documents.sort(
      (a, b) =>
        new Date(b.node._meta.firstPublicationDate).getTime() -
        new Date(a.node._meta.firstPublicationDate).getTime(),
    );

  return orderedDocuments;
};
