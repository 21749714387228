import { motion } from 'framer-motion';
import React from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { galleryStyles } from './Gallery.styles';

interface Props {
  images: { imageSrc?: string; originalAlt: string }[];
  indexOfImage: number;
}

export const Gallery: React.FC<Props> = ({ images, indexOfImage }) => {
  const imagesData = images.map(({ imageSrc, originalAlt }) => {
    const original = imageSrc ? imageSrc : '';
    return { original, originalAlt };
  });
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      css={galleryStyles.overlay}
    >
      <div css={galleryStyles.wrapper} onClick={(e) => e.stopPropagation()}>
        <ImageGallery
          items={imagesData}
          showPlayButton={false}
          showFullscreenButton={false}
          infinite={false}
          showIndex={true}
          startIndex={indexOfImage}
          lazyLoad={true}
        />
      </div>
    </motion.div>
  );
};
