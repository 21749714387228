import { SeoTags } from 'components/seo-tags';
import { Link } from 'gatsby';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import React from 'react';
import { notFoundComponentStyles } from './NotFoundComponent.styles';

export const NotFoundComponent: React.FC = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <SeoTags
        title={formatMessage({ id: 'not-found.seo.title' })}
        description={formatMessage({ id: 'not-found.seo.description' })}
      />
      <div css={notFoundComponentStyles.wrapper}>
        <p css={notFoundComponentStyles.messagePrimary}>
          <FormattedMessage id="not-found.paragraph-one" />
        </p>
        <p css={notFoundComponentStyles.messageSecondary}>
          <FormattedMessage id="not-found.paragraph-two" />{' '}
          <Link to="/" css={notFoundComponentStyles.messageLink}>
            <FormattedMessage id="not-found.home-page" />
          </Link>
          .
        </p>
      </div>
    </>
  );
};
