import React from 'react';
import { checkbox } from './InputCheckbox.styles';

interface Props {
  title: string;
  checked?: boolean;
  onChange: (value: boolean, section: ProgramCategory) => void;
  section: ProgramCategory;
}

export const InputCheckbox: React.FC<Props> = ({
  title,
  checked,
  onChange,
  section,
}) => {
  return (
    <div>
      <label
        css={[
          checkbox.inputCheckbox,
          checked ? checkbox.labelActive : checkbox.labelInactive,
        ]}
      >
        <input
          type="checkbox"
          name={title}
          id={title}
          onChange={() => onChange(!checked, section)}
          checked={checked}
        />
        <span css={checkbox.arrow}></span>
        {title}
      </label>
    </div>
  );
};
