import { Grid } from 'components';
import React from 'react';
import ArrowRight from 'assets/icons/arrowRight.svg';
import { header } from './ListHeader.styles';
import { useDispatch, useSelector } from 'react-redux';
import { blogActions, blogSelector } from 'modules/blog';
import { FormattedMessage, Link, useIntl } from 'gatsby-plugin-react-intl';

interface Props {
  title: string;
  categories?: BlogCategory[];
}

export const ListHeader: React.FC<Props> = ({ title, categories }) => {
  const dispatch = useDispatch();
  const { category } = useSelector(blogSelector.getState);
  const { formatMessage } = useIntl();

  return (
    <section css={header.root}>
      <Grid type="page">
        <h1 css={header.title}>{title}</h1>
        <div css={header.descriptionWrapper}>
          <Link to="/" css={header.link}>
            <FormattedMessage id="breadcrumb.home" />
          </Link>
          <ArrowRight css={header.arrow} />
          <p css={header.description}>
            {title === 'Destinations'
              ? formatMessage({ id: 'breadcrumb.destinations' })
              : title}
          </p>
        </div>
        {categories && (
          <div css={header.filterWrapper}>
            {categories.map((item) => (
              <button
                key={item}
                css={item === category ? header.buttonActive : header.button}
                onClick={() => dispatch(blogActions.setCategory(item))}
              >
                {item}
              </button>
            ))}
          </div>
        )}
      </Grid>
    </section>
  );
};
