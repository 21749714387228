import { Grid } from 'components';
import { motion } from 'framer-motion';
import React from 'react';
import InView from 'react-intersection-observer';
import { details } from './DetailsItem.styles';

export const DetailsItem: React.FC = ({ children }) => (
  <Grid type="page">
    <InView threshold={0.1} triggerOnce={true}>
      {({ ref, inView }) => (
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: '40px' }}
          animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '40px' }}
          transition={{ ease: 'easeInOut', duration: 0.6 }}
          css={details.contentWrapper}
        >
          {children}
        </motion.div>
      )}
    </InView>
  </Grid>
);
