import { css } from '@emotion/react';
import { breakpoints, colors } from 'style';

const root = css`
  grid-column: 3 / span 8;

  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-regular);
  margin-bottom: calc(var(--unit) * 12);

  @media ${breakpoints.medium} {
    margin-bottom: calc(var(--unit) * 20);
  }

  & > h2,
  h3 {
    font-family: var(--font-family-primary);
    font-size: var(--font-size-xLarge);
    line-height: var(--line-height-xxLarge);
    font-weight: var(--font-weight-regular);

    margin-bottom: calc(var(--unit) * 9);

    @media ${breakpoints.medium} {
      font-size: var(--font-size-xxLarge);
      line-height: var(--line-height-xxxLarge);
      margin-bottom: var(--spacing-huge);
    }
  }

  & > h4,
  h5 {
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
    font-family: var(--font-family-secondary);
    font-weight: var(--font-weight-custom500);

    margin-bottom: var(--spacing-med);
    color: ${colors.textLight};
  }

  & > p,
  li {
    font-size: var(--font-size-base);
    line-height: var(--line-height-base);
    font-family: var(--font-family-secondary);
    font-weight: var(--font-weight-regular);

    margin-bottom: var(--spacing-med);
    color: ${colors.textLight};
  }

  & > ul {
    padding: 0 var(--spacing-xlrg);
  }

  & > p {
    margin-bottom: calc(var(--unit) * 12);
  }

  & > div > div > div > a > h1,
  div > div > div > a > p {
    display: none;
  }

  & > div > div > div > a > img {
    margin-bottom: calc(var(--unit) * 23);
  }
`;

const title = css`
  font-family: var(--font-family-primary);
  font-size: var(--font-size-xLarge);
  line-height: var(--line-height-xxLarge);
  font-weight: var(--font-weight-regular);

  margin-bottom: var(--spacing-xxxlrg);

  @media ${breakpoints.medium} {
    font-size: var(--font-size-xxLarge);
    line-height: var(--line-height-xxxLarge);
    margin-bottom: var(--spacing-huge);
  }
`;

const contentWrapper = css`
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-regular);

  margin-bottom: calc(var(--unit) * 12);
  color: ${colors.textLight};
`;

const contentSubtitle = css`
  font-weight: var(--font-weight-custom500);

  margin-bottom: var(--spacing-med);
`;

const contentParagraph = css`
  margin-bottom: calc(var(--unit) * 12);
`;

export const programSummary = {
  root,
  title,
  contentWrapper,
  contentSubtitle,
  contentParagraph,
};
