import { css } from '@emotion/react';
import { breakpoints, colors } from 'style';

const wrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: calc(var(--unit) * 25) calc(var(--unit) * 5);

  @media ${breakpoints.medium} {
    padding: calc(var(--unit) * 70) calc(var(--unit) * 20);
  }
`;

const messagePrimary = css`
  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-large);
  line-height: var(--line-height-large);

  @media ${breakpoints.medium} {
    font-size: var(--font-size-xxxLarge);
    line-height: var(--line-height-xxxLarge);
  }
`;

const messageSecondary = css`
  ${messagePrimary}
  font-size: var(--font-size-medium);
  line-height: var(--line-height-medium);

  @media ${breakpoints.medium} {
    font-size: var(--font-size-xxxLarge);
    line-height: var(--line-height-xxxLarge);
  }
`;

const messageLink = css`
  ${messageSecondary}
  color: ${colors.primary};
  transition: all 0.3s ease-out;

  &:hover {
    color: ${colors.secondary};
  }
`;

export const notFoundComponentStyles = {
  wrapper,
  messagePrimary,
  messageSecondary,
  messageLink,
};
