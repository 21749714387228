import { RouteComponentProps } from '@reach/router';
import { ContentList, SeoTags } from 'components';
import { getSrc } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-react-intl';
import { useImages } from 'hooks';
import React from 'react';
import { DestinationsHeader } from './components';

interface Props extends RouteComponentProps {
  documents: PrismicDocument[];
}

export const DestinationPage: React.FC<Props> = ({ documents }) => {
  const { destinationsMeta } = useImages();
  const { formatMessage } = useIntl();

  return (
    <>
      <SeoTags
        title={formatMessage({ id: 'destinations.seo.title' })}
        description={formatMessage({ id: 'destinations.seo.description' })}
        imagePath={getSrc(destinationsMeta)}
      />
      <DestinationsHeader
        title={formatMessage({ id: 'destinations.header.title' })}
      />
      <ContentList documents={documents} path="destinations" />
    </>
  );
};
