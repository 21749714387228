import { css } from '@emotion/react';
import { breakpoints, colors } from 'style';

const root = css`
  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-regular);
  margin-bottom: calc(var(--unit) * 22);

  @media ${breakpoints.medium} {
    grid-column: 3 / span 8;
    margin-bottom: calc(var(--unit) * 20);
  }
`;

const title = css`
  font-family: var(--font-family-primary);
  font-size: var(--font-size-xLarge);
  line-height: var(--line-height-xxLarge);
  font-weight: var(--font-weight-regular);

  margin-bottom: var(--spacing-xxxlrg);

  @media ${breakpoints.medium} {
    font-size: var(--font-size-xxLarge);
    line-height: var(--line-height-xxxLarge);
    margin-bottom: var(--spacing-huge);
  }
`;

const packageInfoWrapper = css`
  display: flex;
  margin-bottom: var(--spacing-lrg);
  width: 100%;
`;

const packageInfoIcon = css`
  margin-right: var(--spacing-xlrg);
  height: 24px;
  width: 24px;

  @media ${breakpoints.medium} {
    margin-right: var(--spacing-lrg);
    height: 32px;
    width: 32px;
  }
`;

const packageInfo = css`
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);

  color: ${colors.textLight};
`;

export const programPackageStyles = {
  root,
  title,
  packageInfoWrapper,
  packageInfoIcon,
  packageInfo,
};
