import { motion } from 'framer-motion';
import { RichText } from 'prismic-reactjs';
import React from 'react';
import InView from 'react-intersection-observer';
import { programSummary } from './ProgramSummary.styles';

interface Props {
  document: ProgramItem;
}

export const ProgramSummary: React.FC<Props> = ({ document }) => {
  const { content } = document.node;
  return (
    <InView threshold={0.1} triggerOnce={true}>
      {({ ref, inView }) => (
        <motion.div
          ref={ref}
          animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
          transition={{ ease: 'circOut', duration: 0.6 }}
          css={programSummary.root}
        >
          <RichText render={content} />
        </motion.div>
      )}
    </InView>
  );
};
