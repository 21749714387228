import React from 'react';
import { textareaStyles } from './Textarea.styles';

interface Props extends React.HTMLProps<HTMLTextAreaElement> {
  labelName: string;
  name: string;
  value: string;
  hasErr?: boolean;
}

export const Textarea: React.FC<Props> = ({
  labelName,
  name,
  value,
  hasErr,
  ...props
}) => {
  return (
    <>
      <label
        css={[
          textareaStyles.labelName,
          hasErr ? textareaStyles.labelNameErr : textareaStyles.labelNameSucc,
        ]}
        htmlFor={name}
      >
        {labelName}
      </label>
      <textarea
        css={[
          textareaStyles.textarea,
          hasErr ? textareaStyles.textareaErr : textareaStyles.textareaSucc,
        ]}
        name={name}
        value={value}
        rows={6}
        {...props}
      />
    </>
  );
};
