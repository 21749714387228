import { gql } from '@apollo/client';

export const GET_BLOGS = gql`
  query Blog($lang: String) {
    allDocuments: allBlog_posts(lang: $lang) {
      edges {
        node {
          _meta {
            uid
            lang
            firstPublicationDate
          }
          title
          content
          image
          category_name
          summary
        }
      }
    }
  }
`;
