import { Grid } from 'components';
import React from 'react';
import ArrowRight from 'assets/icons/arrowRight.svg';
import { header } from '../styles/Header.styles';
import { useDispatch, useSelector } from 'react-redux';
import { blogActions, blogSelector } from '../redux';
import { motion } from 'framer-motion';
import { FormattedMessage, Link } from 'gatsby-plugin-react-intl';

interface Props {
  title: string;
  categories?: BlogCategory[];
}

export const Header: React.FC<Props> = ({ title, categories }) => {
  const dispatch = useDispatch();
  const { category } = useSelector(blogSelector.getState);

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: 'easeOut', duration: 0.6 }}
      css={header.root}
    >
      <Grid type="page">
        <h1 css={header.title}>{title}</h1>
        <div css={header.descriptionWrapper}>
          <Link to="/" css={header.link}>
            <FormattedMessage id="breadcrumb.home" />
          </Link>
          <ArrowRight css={header.arrow} />
          <p css={header.description}>{title}</p>
        </div>
        {categories && (
          <div css={header.filterWrapper}>
            {categories.map((item) => (
              <button
                key={item}
                css={item === category ? header.buttonActive : header.button}
                onClick={() => dispatch(blogActions.setCategory(item))}
              >
                <FormattedMessage id={`categories.${item.toLowerCase()}`} />
              </button>
            ))}
          </div>
        )}
      </Grid>
    </motion.section>
  );
};
