import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { usePrismicImages } from 'hooks';
import React from 'react';
import { styles } from './ProgramItem.styles';

interface Props {
  item: ProgramItem;
}

export const ProgramItemImage: React.FC<Props> = ({ item }) => {
  const { programQuery } = usePrismicImages();
  const image = programQuery.edges.find(
    (edge: { node: { uid: string } }) => edge.node.uid === item.node._meta.uid,
  )?.node.data.program_images[0].image;
  const imageData = getImage(image);

  return (
    <>
      {imageData && image ? (
        <GatsbyImage
          loading="lazy"
          image={imageData}
          alt={item.node.program_images[0].image.alt || ''}
          css={styles.img}
        />
      ) : (
        <img
          loading="lazy"
          css={styles.img}
          src={item.node.program_images[0].image.url}
          alt={item.node.program_images[0].image.alt}
        />
      )}
    </>
  );
};
