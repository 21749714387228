import { css } from '@emotion/react';
import { grid } from '@prototyp/gatsby-plugin-gumball/utils';
import { breakpoints, colors, fontFamily } from 'style';

const root = css`
  padding-top: calc(var(--unit) * 35);
`;

const category = css`
  color: ${colors.text};
  font-family: ${fontFamily.secondary};
  font-size: calc(var(--unit) * 3 + 2);
  line-height: calc(var(--unit) * 4);
  letter-spacing: calc(var(--unit) * 0.5);
  text-transform: uppercase;
  margin-bottom: calc(var(--unit) * 2);
  ${grid.colSpan.c6}
  ${grid.colStart.c4}
`;

const title = css`
  color: ${colors.text};
  font-family: ${fontFamily.primary};
  font-size: calc(var(--unit) * 9);
  font-weight: var(--font-weight-regular);
  line-height: calc(var(--unit) * 12);
  margin-bottom: calc(var(--unit) * 11);

  ${grid.colSpan.c6}
  ${grid.colStart.c4}

  @media ${breakpoints.medium} {
    margin-bottom: calc(var(--unit) * 9);
    font-size: var(--font-size-xxLarge);
    line-height: var(--line-height-xxLarge);
  }

  @media ${breakpoints.large} {
    font-size: var(--font-size-huge);
    line-height: var(--line-height-huge);
  }
`;

const imgWrapper = css`
  ${grid.colSpan.c12}
  ${grid.colStart.c1}
  margin-bottom: calc(var(--unit) * 6);

  @media ${breakpoints.medium} {
    ${grid.colSpan.c8}
    ${grid.colStart.c3}
  }
`;

const img = css`
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
`;

const linkWrapper = css`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  overflow-x: auto;
  box-sizing: content-box;
  ${grid.colSpan.c6}
  ${grid.colStart.c4}
`;

const titleSecondary = css`
  color: ${colors.textLight};
  font-family: ${fontFamily.secondary};
  font-size: 14px;
  line-height: calc(var(--unit) * 5);
`;

const current = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const link = css`
  ${titleSecondary}
  position: relative;
  min-width: max-content;

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: ${colors.secondary};
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    color: ${colors.secondary};
  }

  &:hover:before {
    visibility: visible;
    width: 100%;
  }
`;

export const header = {
  root,
  category,
  title,
  imgWrapper,
  img,
  linkWrapper,
  titleSecondary,
  link,
  current,
};
