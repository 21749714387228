import { DocumentNode, useQuery } from '@apollo/client';
import { GET_BLOGS, GET_DESTINATIONS } from 'queries';

export const usePrismicDocuments = (
  locale: string,
  type: 'destination' | 'blog',
) => {
  let lang = locale;
  let query: DocumentNode = GET_DESTINATIONS;

  if (locale === 'en') lang = 'en-eu';
  if (locale === 'de') lang = 'de-de';
  if (locale === 'sr') lang = 'rs';
  if (type === 'blog') query = GET_BLOGS;

  const documents: PrismicDocument[] = useQuery(query, {
    variables: { lang },
  }).data?.allDocuments?.edges;

  const orderedDocuments =
    documents &&
    documents.sort(
      (a, b) =>
        new Date(b.node._meta.firstPublicationDate).getTime() -
        new Date(a.node._meta.firstPublicationDate).getTime(),
    );

  return orderedDocuments;
};
