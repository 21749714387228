import { useLocation } from '@reach/router';
import { getSrc } from 'gatsby-plugin-image';
import { useImages } from 'hooks';
import { useSiteMetadata } from 'hooks/useSiteMetadata';
import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  title?: string;
  description?: string;
  imagePath?: string;
}

export const SeoTags: React.FC<Props> = ({ title, description, imagePath }) => {
  const { headerImage } = useImages();
  const { pathname } = useLocation();
  const { defaultTitle, defaultDescription, siteUrl } = useSiteMetadata();
  const seoTitle = title ? title : defaultTitle;
  const seoDescription = description ? description : defaultDescription;
  const url = `${siteUrl}${pathname}`;
  const seoImagePath = imagePath
    ?.toString()
    .includes('https://images.prismic.io')
    ? imagePath
    : `${siteUrl}${imagePath}`;
  const backUpImagePath = `${siteUrl}${getSrc(headerImage)}`;

  return (
    <>
      <Helmet>
        <title>{seoTitle}</title>
        <meta property="og:title" content={seoTitle} />
        <meta property="og:image" content={seoImagePath || backUpImagePath} />
        <meta name="twitter:image" content={seoImagePath || backUpImagePath} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seoTitle} />
        <meta property="og:url" content={url} />
        <meta name="description" content={seoDescription} />
        <meta property="og:description" content={seoDescription} />
        <meta name="twitter:description" content={seoDescription} />
      </Helmet>

      {url && (
        <Helmet>
          <link rel="canonical" href={url} />
          <link rel="og:url" href={url} />
          <link rel="twitter:url" href={url} />
        </Helmet>
      )}
    </>
  );
};
