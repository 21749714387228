import { css } from '@emotion/react';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';
import { colors } from 'style';

const inputCheckbox = css`
  display: block;
  position: relative;
  padding-left: 38px;
  margin-bottom: var(--spacing-lrg);
  cursor: pointer;
  user-select: none;
  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-custom500);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);

  @media ${breakpoints.mediumMax} {
    padding-right: calc(var(--unit) * 12);
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &:hover input ~ span {
    background-color: ${(colors.primary, 0.2)};
  }

  & input:checked ~ span {
    background-color: transparent;
  }

  & input:checked ~ span:after {
    display: block;
  }
`;

const arrow = css`
  position: absolute;
  top: 0;
  left: 0;
  height: calc(var(--unit) * 5);
  width: calc(var(--unit) * 5);
  border: 1px solid ${colors.borderLight};
  border-radius: 4px;
  transition: background-color 0.15s ease-out;

  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 7px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid ${colors.primary};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

const labelActive = css`
  color: ${colors.primary};
`;

const labelInactive = css`
  color: ${colors.text};
`;

export const checkbox = {
  inputCheckbox,
  labelActive,
  labelInactive,
  arrow,
};
