import React from 'react';
import { inputStyles } from './Input.styles';

interface Props extends React.HTMLProps<HTMLInputElement> {
  labelName: string;
  name: string;
  handleChange?: VoidFunction;
  value: string;
  hasErr?: boolean;
}

export const Input: React.FC<Props> = ({
  labelName,
  name,
  handleChange,
  value,
  hasErr,
  ...props
}) => {
  return (
    <>
      <label
        css={[
          inputStyles.labelName,
          hasErr ? inputStyles.labelNameErr : inputStyles.labelNameSucc,
        ]}
        htmlFor={name}
      >
        {labelName}
      </label>
      <input
        css={[
          inputStyles.input,
          hasErr ? inputStyles.inputErr : inputStyles.inputSucc,
        ]}
        name={name}
        onChange={handleChange}
        value={value}
        {...props}
      />
    </>
  );
};
