import { RouteComponentProps, useParams } from '@reach/router';
import { Grid, SeoTags } from 'components';
import { Loading, loadingStyles } from 'components/loading';
import React from 'react';
import { ProgramDetailsHeader } from '../header';
import { ProgramPackage } from '../program-package';
import { ProgramSummary } from '../summary';
import { programDetailPageStyles } from './ProgramDetailsPage.styles';

interface Props extends RouteComponentProps {
  documents: ProgramItem[];
}

export const ProgramDetailsPage: React.FC<Props> = ({ documents }) => {
  const { uid } = useParams();
  const item = documents?.find((item) => item.node._meta.uid === uid);
  const descriptionLength =
    item?.node.description && item?.node.description[0].text.length;

  function getDescription(item?: ProgramItem) {
    if (item?.node.description && descriptionLength > 150)
      return item?.node.description[0].text.slice(0, 151) + '...';

    if (item?.node.description && descriptionLength <= 150)
      return item?.node.description[0].text;

    return undefined;
  }

  const description = getDescription(item);

  if (!item) {
    return (
      <div css={loadingStyles.loadingWrapper}>
        <Loading />;
      </div>
    );
  }

  return (
    <>
      <SeoTags
        title={`Panona - ${item.node.title[0].text}`}
        description={description}
        imagePath={item.node.program_images[0].image.url}
      />
      <div css={programDetailPageStyles.wrapper}>
        <ProgramDetailsHeader document={item} />
        <Grid type="page">
          <ProgramPackage document={item} />
          <ProgramSummary document={item} />
        </Grid>
      </div>
    </>
  );
};
