import { useSelector } from 'react-redux';
import { blogSelector } from '../redux';

export const useCategoryFilter = () => {
  const { category } = useSelector(blogSelector.getState);

  function categoryFilter(documents: PrismicDocument[]) {
    if (!documents) return;
    if (category === 'All') return documents;

    return documents.filter((item) => item.node.category_name === category);
  }

  return {
    categoryFilter,
  };
};
