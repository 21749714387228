import Slider from 'react-slick';
import React, { useRef, useState } from 'react';
import { sliderStyles } from './SliderComponent.style';
import ArrowRightGreen from 'assets/icons/arrowRightGreen.svg';
import ArrowLeftGreen from 'assets/icons/arrowLeftGreen.svg';

interface Props {
  itemsPerSlide: number;
  slidesToScroll: number;
  numberOfItems: number;
}

export const SliderComponent: React.FC<Props> = ({
  children,
  itemsPerSlide,
  slidesToScroll,
  numberOfItems,
}) => {
  const sliderRef = useRef<Slider>();
  const [sliderNumber, setSliderNumber] = useState({ before: 0, current: 0 });
  const maxSlides = Math.ceil(numberOfItems / itemsPerSlide);

  const SamplePrevArrow: React.FC = () => {
    return (
      <div
        css={[
          sliderStyles.arrowLeft,
          sliderNumber.current === 0
            ? sliderStyles.arrowDisabled
            : sliderStyles.arrowActive,
        ]}
        onClick={() => {
          sliderRef.current?.slickPrev();
        }}
      >
        <ArrowLeftGreen />
      </div>
    );
  };

  const SampleNextArrow: React.FC = () => {
    return (
      <div
        css={[
          sliderStyles.arrowRight,
          Math.ceil(sliderNumber.current / itemsPerSlide) + 1 === maxSlides
            ? sliderStyles.arrowDisabled
            : sliderStyles.arrowActive,
        ]}
        onClick={() => {
          sliderRef.current?.slickNext();
        }}
      >
        <ArrowRightGreen />
      </div>
    );
  };

  const SampleNextArrowSpecial: React.FC = () => {
    return (
      <div
        css={[
          sliderStyles.arrowRight,
          sliderNumber.current + 1 === numberOfItems
            ? sliderStyles.arrowDisabled
            : sliderStyles.arrowActive,
        ]}
        onClick={() => {
          sliderRef.current?.slickNext();
        }}
      >
        <ArrowRightGreen />
      </div>
    );
  };

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: itemsPerSlide,
    slidesToScroll,
    infinite: false,
    variableWidth: false,
    adaptiveHeight: false,
    beforeChange: (oldIndex: number, newIndex: number) => {
      setSliderNumber({ before: oldIndex, current: newIndex });
    },
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          variableWidth: false,
          adaptiveHeight: true,
          nextArrow: <SampleNextArrowSpecial />,
        },
      },
    ],
  };

  return (
    <Slider ref={sliderRef} css={sliderStyles.wrapper} {...settings}>
      {children}
    </Slider>
  );
};
