import {
  applyMiddleware,
  combineReducers,
  createStore,
  ReducersMapObject,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

export function createReduxStore(appReducer: ReducersMapObject<AppState>) {
  const composeEnhancers = composeWithDevTools({});

  return createStore(
    combineReducers<AppState>(appReducer),
    {},
    process.env.NODE_ENV === 'production'
      ? applyMiddleware()
      : composeEnhancers(applyMiddleware()),
  );
}
