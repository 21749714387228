import React from 'react';
import { PrismicLink } from 'apollo-link-prismic';
import { Provider as ReduxProvider } from 'react-redux';
import { createReduxStore } from 'modules/redux-store';
import { blogReducer } from 'modules/blog';
import { destinationReducer } from 'modules/destinations';
import { programReducer } from 'modules/program';
import { IntlProvider } from 'gatsby-plugin-react-intl';
import { ApolloClient } from 'apollo-client';
import { ApolloProvider } from '@apollo/client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import 'firebase/compat/firestore';

const rootReducer = {
  blog: blogReducer,
  destination: destinationReducer,
  program: programReducer,
};

export const onClientEntry = () => {
  firebase.initializeApp({
    apiKey: process.env.GATSBY_API_KEY,
    authDomain: process.env.GATSBY_AUTH_DOMAIN,
    projectId: process.env.GATSBY_PROJECT_ID,
    storageBucket: process.env.GATSBY_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_MESSAGING_SENDER_ID,
    appId: process.env.GATSBY_APP_ID,
  });

  if (process.env.GATSBY_FIREBASE_ENV === 'local') {
    firebase.app().functions().useEmulator('localhost', 5001);
    firebase.app().firestore().useEmulator('localhost', 8080);
  }
};

export const wrapRootElement = ({ element }) => {
  const prismicClient = new ApolloClient({
    link: new PrismicLink({
      uri: process.env.PRISMIC_GRAPHQL_URL,
      accessToken: process.env.PRISMIC_ACCESS_TOKEN,
    }),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={prismicClient}>
      <IntlProvider locale="hr" defaultLocale="hr">
        <ReduxProvider store={createReduxStore(rootReducer)}>
          {element}
        </ReduxProvider>
      </IntlProvider>
    </ApolloProvider>
  );
};
