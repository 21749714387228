import React from 'react';
import { skeletonStyles } from './Skeleton.styles';

interface Props {
  cardType: string;
}

export const Skeleton: React.FC<Props> = ({ cardType }) => {
  return (
    <>
      {cardType === 'program' ? (
        <div css={skeletonStyles.wrapperSecondary}>
          <div css={skeletonStyles.skeletonImageSecondary} />
          <div css={skeletonStyles.wrapperTertiary}>
            <div css={skeletonStyles.skeletonCategoryNameSecondary} />
            <div css={skeletonStyles.skeletonTitleSecondary} />
            <div css={skeletonStyles.skeletonInfoPrimary} />
            <div css={skeletonStyles.skeletonInfoSecondary} />
            <div css={skeletonStyles.skeletonInfoTertiary} />
          </div>
        </div>
      ) : (
        <div css={skeletonStyles.wrapperPrimary}>
          <div css={skeletonStyles.skeletonImagePrimary} />
          {cardType === 'blog' && (
            <div css={skeletonStyles.skeletonCategoryNamePrimary} />
          )}
          <div css={skeletonStyles.skeletonTitlePrimary} />
          <div css={skeletonStyles.skeletonDescriptionPrimary} />
          <div css={skeletonStyles.skeletonDescriptionSecondary} />
        </div>
      )}
    </>
  );
};
