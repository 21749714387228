import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { ContentList, SeoTags } from 'components';
import { useCategoryFilter } from './hooks';
import { useImages } from 'hooks';
import { getSrc } from 'gatsby-plugin-image';
import { Header } from './components';
import { useIntl } from 'gatsby-plugin-react-intl';

interface Props extends RouteComponentProps {
  documents: PrismicDocument[];
}

export const BlogPage: React.FC<Props> = ({ documents }) => {
  const { categoryFilter } = useCategoryFilter();
  const { blogMeta } = useImages();
  const { formatMessage } = useIntl();

  return (
    <>
      <SeoTags
        title={formatMessage({ id: 'blog.seo.title' })}
        description={formatMessage({ id: 'blog.seo.description' })}
        imagePath={getSrc(blogMeta)}
      />
      <Header
        title={formatMessage({ id: 'blog.header.title' })}
        categories={[
          'All',
          'Sport',
          'Gastronomy',
          'Nature',
          'Sights',
          'Destinations',
          'Vineyards',
          'Entertainment',
        ]}
      />
      <ContentList documents={categoryFilter(documents)} path="blog" />
    </>
  );
};
