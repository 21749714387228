import { gql } from '@apollo/client';

export const GET_PROGRAMS = gql`
  query Program($lang: String) {
    allPrograms(lang: $lang) {
      edges {
        node {
          _meta {
            uid
            lang
            firstPublicationDate
          }
          title
          category
          description
          summary
          age_restrictions
          challenge_level
          category
          content
          event_duration
          package_type
          program {
            program_title
          }
          price
          program_images {
            image
          }
        }
      }
    }
  }
`;
