import { destinationActions } from './actions';

interface ReducerState {
  readonly category?: DestinationCategory;
}

const INITIAL_STATE: ReducerState = {
  category: 'All',
};

export const destinationReducer = (
  state: ReducerState = INITIAL_STATE,
  action: ActionUnion<typeof destinationActions>,
): ReducerState => {
  switch (action.type) {
    case 'destination/SET_CATEGORY':
      return {
        ...state,
        category: action.payload.category,
      };

    default:
      return state || INITIAL_STATE;
  }
};
