import { css } from '@emotion/react';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';
import { colors, fontFamily } from 'style';

const root = css`
  grid-column: 1 / 12;
  @media ${breakpoints.large} {
    grid-column: 2 / 5;
  }
`;

const contentWrapper = css`
  padding: var(--spacing-huge) var(--spacing-xxlrg);
  border: 1px solid ${colors.borderLight};
  border-radius: var(--spacing-sml);
  margin-bottom: var(--spacing-huge);

  @media ${breakpoints.mediumMax} {
    display: none;
  }
`;

const title = css`
  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-custom500);
  font-size: var(--font-size-medium);
  line-height: 150%;
  margin-bottom: var(--spacing-xxlrg);
`;

const filterWrapper = css`
  display: none;

  @media ${breakpoints.mediumMax} {
    font-family: ${fontFamily.secondary};
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    overflow-x: auto;
    margin-bottom: var(--spacing-xlrg);
  }
`;

const buttonActive = css`
  border: none;
  background-color: transparent;
  font-weight: var(--font-weight-bold);
  font-size: 14px;
  color: ${colors.text};
  cursor: pointer;
  padding: var(--spacing-sml) 0;
  margin: 0 var(--spacing-med);
  position: relative;
  width: fit-content;
  border-bottom: 1px solid ${colors.primary};
`;

const button = css`
  ${buttonActive}
  color: ${colors.border};
  font-weight: var(--font-weight-regular);
  border: none;

  &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: ${colors.primary};
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  &:hover:before {
    visibility: visible;
    width: 100%;
  }
`;

export const aside = {
  root,
  contentWrapper,
  title,
  filterWrapper,
  buttonActive,
  button,
};
