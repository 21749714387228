import ActivityLevelSvg from 'assets/icons/activityLevel.svg';
import AgeRestrictionSvg from 'assets/icons/ageRestriction.svg';
import ArrowRightSvg from 'assets/icons/arrowRight.svg';
import ClockSvg from 'assets/icons/clock.svg';
import CloseSvg from 'assets/icons/close.svg';
import GallerySvg from 'assets/icons/gallery.svg';
import { Grid } from 'components';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import { programDetailsHeaderStyles } from './ProgramDetailsHeader.styles';
import ImageGallery from 'react-image-gallery';
import InView from 'react-intersection-observer';
import { margin } from '@prototyp/gatsby-plugin-gumball/utils';
import { FormattedMessage, Link } from 'gatsby-plugin-react-intl';

interface Props {
  document: ProgramItem;
}

export const ProgramDetailsHeader: React.FC<Props> = ({ document }) => {
  const { title, event_duration, age_restrictions, challenge_level } =
    document.node;
  const [isShowClicked, setIsShowClicked] = useState<boolean>(false);

  function handleEsc(e: KeyboardEvent) {
    if (e.keyCode === 27) {
      setIsShowClicked(false);
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <>
      <div css={programDetailsHeaderStyles.imageWrapper}>
        {document.node.program_images.slice(0, 3).map(({ image }) => (
          <motion.img
            loading="lazy"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ ease: 'easeInOut', duration: 0.4 }}
            key={image.url}
            css={programDetailsHeaderStyles.featuredImage}
            src={image.url}
            alt={image.alt}
            onClick={() => setIsShowClicked(true)}
          />
        ))}
        <motion.button
          css={[
            programDetailsHeaderStyles.button,
            isShowClicked
              ? programDetailsHeaderStyles.buttonHidden
              : programDetailsHeaderStyles.galleryButtonFullscreen,
          ]}
          initial={{ opacity: 0, y: '40px' }}
          animate={{ opacity: 1, y: '0' }}
          transition={{ ease: 'easeInOut', duration: 0.5 }}
          onClick={() => setIsShowClicked(true)}
        >
          <span
            css={
              (programDetailsHeaderStyles.galleryButtonText, margin.right.tny)
            }
          >
            <FormattedMessage id="button.show-images" />
          </span>
          <GallerySvg />
        </motion.button>
      </div>
      <AnimatePresence>
        {isShowClicked && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            css={programDetailsHeaderStyles.overlay}
            onClick={() => setIsShowClicked(false)}
          >
            <button
              css={[
                programDetailsHeaderStyles.button,
                programDetailsHeaderStyles.galleryButtonClose,
              ]}
              onClick={(e) => {
                setIsShowClicked(false);
                e.stopPropagation();
              }}
            >
              <CloseSvg />
            </button>
            <div
              css={[
                programDetailsHeaderStyles.galleryWrapper,
                programDetailsHeaderStyles.gallery,
              ]}
              onClick={(e) => e.stopPropagation()}
            >
              <ImageGallery
                items={document.node.program_images.map(({ image }) => ({
                  original: image.url,
                  originalAlt: image.alt,
                }))}
                showPlayButton={false}
                showFullscreenButton={false}
                infinite={false}
                showIndex={true}
                lazyLoad={true}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <Grid type="page">
        <div css={programDetailsHeaderStyles.wrapper}>
          <InView threshold={0.4} triggerOnce={true}>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                initial={{ opacity: 0, y: '40px' }}
                animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '40px' }}
                transition={{ ease: 'easeInOut', duration: 0.6 }}
                css={programDetailsHeaderStyles.breadcrumbsWrapper}
              >
                <Link css={programDetailsHeaderStyles.breadcrumb} to="/">
                  <FormattedMessage id="breadcrumb.home" />
                </Link>
                <ArrowRightSvg css={programDetailsHeaderStyles.svg} />
                <Link
                  css={programDetailsHeaderStyles.breadcrumb}
                  to="/program/"
                >
                  <FormattedMessage id="breadcrumb.packages" />
                </Link>
                <ArrowRightSvg css={programDetailsHeaderStyles.svg} />
                <p css={programDetailsHeaderStyles.currentBreadcrumb}>
                  {title[0].text}
                </p>
              </motion.div>
            )}
          </InView>
        </div>
        <div css={programDetailsHeaderStyles.wrapperSecondary}>
          <InView threshold={0.4} triggerOnce={true}>
            {({ ref, inView }) => (
              <motion.h1
                ref={ref}
                initial={{ opacity: 0, y: '40px' }}
                animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '40px' }}
                transition={{ ease: 'easeInOut', duration: 0.5 }}
                css={programDetailsHeaderStyles.title}
              >
                {title[0].text}
              </motion.h1>
            )}
          </InView>
        </div>
      </Grid>
      <Grid type="page">
        <div
          css={[
            programDetailsHeaderStyles.wrapper,
            programDetailsHeaderStyles.gap,
          ]}
        >
          <InView threshold={0.4} triggerOnce={true}>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                initial={{ opacity: 0, y: '40px' }}
                animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '40px' }}
                transition={{ ease: 'easeInOut', duration: 0.6 }}
                css={programDetailsHeaderStyles.eventInfoWrapper}
              >
                <ClockSvg css={programDetailsHeaderStyles.eventInfoIcon} />
                <div>
                  <p css={programDetailsHeaderStyles.eventInfo}>
                    {event_duration[0].text}
                  </p>
                  <p css={programDetailsHeaderStyles.eventInfoCategoryName}>
                    <FormattedMessage id="packages.list.item.duration" />
                  </p>
                </div>
              </motion.div>
            )}
          </InView>
          <InView threshold={0.4} triggerOnce={true}>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                initial={{ opacity: 0, y: '40px' }}
                animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '40px' }}
                transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.1 }}
                css={programDetailsHeaderStyles.eventInfoWrapper}
              >
                <AgeRestrictionSvg
                  css={programDetailsHeaderStyles.eventInfoIcon}
                />
                <div>
                  <p css={programDetailsHeaderStyles.eventInfo}>
                    {age_restrictions[0].text}
                  </p>
                  <p css={programDetailsHeaderStyles.eventInfoCategoryName}>
                    <FormattedMessage id="packages.list.item.age" />
                  </p>
                </div>
              </motion.div>
            )}
          </InView>
          <InView threshold={0.4} triggerOnce={true}>
            {({ ref, inView }) => (
              <motion.div
                ref={ref}
                initial={{ opacity: 0, y: '40px' }}
                animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '40px' }}
                transition={{ ease: 'easeInOut', duration: 0.6, delay: 0.2 }}
                css={programDetailsHeaderStyles.eventInfoWrapper}
              >
                <ActivityLevelSvg
                  css={programDetailsHeaderStyles.eventInfoIcon}
                />
                <div>
                  <p css={programDetailsHeaderStyles.eventInfo}>
                    {challenge_level[0].text}
                  </p>
                  <p css={programDetailsHeaderStyles.eventInfoCategoryName}>
                    <FormattedMessage id="packages.list.item.activity" />
                  </p>
                </div>
              </motion.div>
            )}
          </InView>
        </div>
      </Grid>
    </>
  );
};
