import { css } from '@emotion/react';
import { breakpoints, colors } from 'style';

const wrapper = css`
  position: relative;
  width: 100%;
`;

const arrowRight = css`
  position: absolute;
  bottom: calc(0px - var(--unit) * 13);
  left: calc(var(--unit) * 16 - 9px);
  z-index: 1;
  padding: calc(var(--unit) * 2 + 1px);
  transform: scale(1.4);
  transition: 0.3s ease-out;

  background-color: ${colors.white};
  cursor: pointer;

  @media ${breakpoints.medium} {
    transform: scale(1);
    left: calc(var(--unit) * 11 - 9px);
    padding: calc(var(--unit) * 3 + 1px) calc(var(--unit) * 3 + 1px);
  }

  &:hover {
    fill: ${colors.secondary};
  }
`;

const arrowLeft = css`
  ${arrowRight}
  left: 0;

  @media ${breakpoints.medium} {
    transform: scale(1);
    left: calc(0px - var(--unit) * 3 - 1px);
  }
`;

const arrowDisabled = css`
  fill: ${colors.borderLight};
  cursor: auto;

  &:hover {
    fill: ${colors.borderLight};
  }
`;

const arrowActive = css`
  fill: ${colors.primary};
`;

export const sliderStyles = {
  wrapper,
  arrowRight,
  arrowLeft,
  arrowDisabled,
  arrowActive,
};
