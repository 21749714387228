import React from 'react';
import { programPackageStyles } from './ProgramPackage.style';
import CheckMark from 'assets/icons/checkMark.svg';
import { motion } from 'framer-motion';
import InView from 'react-intersection-observer';

interface Props {
  document: ProgramItem;
}

export const ProgramPackage: React.FC<Props> = ({ document }) => {
  return (
    <div css={programPackageStyles.root}>
      <InView threshold={0.4} triggerOnce={true}>
        {({ ref, inView }) => (
          <motion.h2
            ref={ref}
            initial={{ opacity: 0, y: '40px' }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '40px' }}
            transition={{ ease: 'easeIn', duration: 0.6 }}
            css={programPackageStyles.title}
          >
            Program
          </motion.h2>
        )}
      </InView>
      {document.node.program.map((item) => (
        <InView
          threshold={0.4}
          triggerOnce={true}
          key={item.program_title[0].text}
        >
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0, y: '40px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '40px' }}
              transition={{ ease: 'easeIn', duration: 0.6 }}
              css={programPackageStyles.packageInfoWrapper}
            >
              <div css={programPackageStyles.packageInfoIcon}>
                <CheckMark />
              </div>
              <p css={programPackageStyles.packageInfo}>
                {item.program_title[0].text}
              </p>
            </motion.div>
          )}
        </InView>
      ))}
    </div>
  );
};
