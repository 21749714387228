import { RouteComponentProps } from '@reach/router';
import { ListHeader, SeoTags } from 'components';
import { getSrc } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-react-intl';
import { useImages } from 'hooks';
import React from 'react';
import { ProgramList } from './components';

interface Props extends RouteComponentProps {
  documents: ProgramItem[];
}

export const ProgramPage: React.FC<Props> = ({ documents }) => {
  const { paketiMeta } = useImages();
  const { formatMessage } = useIntl();

  return (
    <>
      <SeoTags
        title={formatMessage({ id: 'packages.seo.title' })}
        description={formatMessage({ id: 'packages.seo.description' })}
        imagePath={getSrc(paketiMeta)}
      />
      <ListHeader title={formatMessage({ id: 'packages.header.title' })} />
      <ProgramList documents={documents} />
    </>
  );
};
