import { grid } from '@prototyp/gatsby-plugin-gumball/utils';
import { Grid, Card } from 'components';
import { motion } from 'framer-motion';
import React from 'react';
import InView from 'react-intersection-observer';
import { contentListStyles } from './ContentList.styles';
import { FormattedMessage, Link } from 'gatsby-plugin-react-intl';
import { Skeleton } from 'components/skeleton';

interface Props {
  documents?: PrismicDocument[];
  path: 'blog' | 'destinations';
}

export const ContentList: React.FC<Props> = ({ documents, path }) => {
  if (!documents)
    return (
      <section css={contentListStyles.wrapper}>
        <Grid type="page">
          {[...Array(6)].map((_, index) => (
            <div
              key={index}
              css={[
                grid.colSpan.c5,
                index % 2 == 0 ? grid.colStart.c2 : grid.colStart.c7,
              ]}
            >
              <Skeleton cardType={path} />
            </div>
          ))}
        </Grid>
      </section>
    );

  return (
    <section css={contentListStyles.wrapper}>
      <Grid type="page">
        {documents?.length ? (
          documents?.map((item, index) => (
            <InView
              threshold={0.4}
              triggerOnce={true}
              key={item.node._meta.uid}
            >
              {({ ref, inView }) => (
                <Link
                  to={`/${path}/${item.node._meta.uid}`}
                  css={[
                    grid.colSpan.c5,
                    index % 2 ? grid.colStart.c7 : grid.colStart.c2,
                  ]}
                >
                  <motion.div
                    ref={ref}
                    initial={{ opacity: 0, y: '0' }}
                    animate={{
                      opacity: inView ? 1 : 0,
                      y: inView ? 0 : '0',
                    }}
                    transition={{
                      ease: 'easeOut',
                      duration: 0.5,
                      delay: index % 2 == 0 ? 0.2 : 0.5,
                    }}
                  >
                    <Card document={item} cardType={path} />
                  </motion.div>
                </Link>
              )}
            </InView>
          ))
        ) : (
          <motion.p
            css={contentListStyles.message}
            initial={{ opacity: 0, y: '20px' }}
            animate={{ opacity: 1, y: '0' }}
            transition={{ ease: 'easeIn', duration: 0.3 }}
          >
            <FormattedMessage id="blog.list.error" />
          </motion.p>
        )}
      </Grid>
    </section>
  );
};
