import { gql } from '@apollo/client';

export const GET_DESTINATIONS = gql`
  query Destination($lang: String) {
    allDocuments: allDestinations(lang: $lang) {
      edges {
        node {
          _meta {
            uid
            lang
            firstPublicationDate
          }
          title
          image
          summary
          content
        }
      }
    }
  }
`;
