import { InputCheckbox } from 'components';
import { motion } from 'framer-motion';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import React from 'react';
import { aside } from '../styles/Aside.styles';

interface Props {
  categories: Record<ProgramCategory, boolean>;
  handleChange: (value: boolean, section: ProgramCategory) => void;
}

export const Aside: React.FC<Props> = ({ categories, handleChange }) => {
  const { formatMessage } = useIntl();

  return (
    <motion.aside
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: 'easeOut', duration: 0.6 }}
      css={aside.root}
    >
      <div css={aside.filterWrapper}>
        <InputCheckbox
          title={formatMessage({ id: 'categories.active' })}
          onChange={handleChange}
          checked={categories['Active']}
          section="Active"
        />
        <InputCheckbox
          title={formatMessage({ id: 'categories.gastro' })}
          onChange={handleChange}
          checked={categories['Gastronomy']}
          section="Gastronomy"
        />
        <InputCheckbox
          title={formatMessage({ id: 'categories.culture' })}
          onChange={handleChange}
          checked={categories['Culture']}
          section="Culture"
        />
        <InputCheckbox
          title={formatMessage({ id: 'categories.nature' })}
          onChange={handleChange}
          checked={categories['Nature']}
          section="Nature"
        />
        <InputCheckbox
          title={formatMessage({ id: 'categories.tours' })}
          onChange={handleChange}
          checked={categories['Tours']}
          section="Tours"
        />
      </div>
      <div css={aside.contentWrapper}>
        <h2 css={aside.title}>
          <FormattedMessage id="packages.aside.title" />
        </h2>

        <InputCheckbox
          title={formatMessage({ id: 'categories.active' })}
          onChange={handleChange}
          checked={categories['Active']}
          section="Active"
        />
        <InputCheckbox
          title={formatMessage({ id: 'categories.gastro' })}
          onChange={handleChange}
          checked={categories['Gastronomy']}
          section="Gastronomy"
        />
        <InputCheckbox
          title={formatMessage({ id: 'categories.culture' })}
          onChange={handleChange}
          checked={categories['Culture']}
          section="Culture"
        />
        <InputCheckbox
          title={formatMessage({ id: 'categories.nature' })}
          onChange={handleChange}
          checked={categories['Nature']}
          section="Nature"
        />
        <InputCheckbox
          title={formatMessage({ id: 'categories.tours' })}
          onChange={handleChange}
          checked={categories['Tours']}
          section="Tours"
        />
      </div>
    </motion.aside>
  );
};
