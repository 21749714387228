import { programActions } from './actions';

interface ReducerState {
  categories: Record<ProgramCategory, boolean>;
}

const INITIAL_STATE: ReducerState = {
  categories: {
    ['Active']: false,
    ['Gastronomy']: false,
    ['Culture']: false,
    ['Nature']: false,
    ['Tours']: false,
  },
};

export const programReducer = (
  state: ReducerState = INITIAL_STATE,
  action: ActionUnion<typeof programActions>,
): ReducerState => {
  switch (action.type) {
    case 'program/SET_CATEGORY':
      return {
        ...state,
        categories: action.payload.categories,
      };

    default:
      return state || INITIAL_STATE;
  }
};
